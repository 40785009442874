import { hexTokenStrategy } from "./strategies/hexTokenStrategy";
import { jwtTokenStrategy } from "./strategies/jwtTokenStrategy";
import { AccountType } from "./AccountType";

export function accountTypeFromToken(
  token: string,
  strategies: Array<(token: string) => AccountType> = [
    hexTokenStrategy,
    jwtTokenStrategy,
  ]
): AccountType {
  return (
    strategies
      .map(strategy => strategy(token))
      .filter(accountType => accountType !== AccountType.UNKNOWN)
      .shift() || AccountType.UNKNOWN
  );
}
