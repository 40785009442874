import * as platform from "platform";
import { environment } from "../environment";

export const browserName = (): string =>
  platform.name && platform.name.toLowerCase();

const platformFamily = platform.os.family;
const platformVersion = platform.os.version;

let FAMILY_OSX = "OS X";
let FAMILY_LINUXI86 = "Linux i686";
let FAMILY_UBUNTU = "Ubuntu";
let FAMILY_WINDOWS = "Windows";
let FAMILY_WINDOWS_NT = "Windows NT";
let FAMILY_DEBIAN = "Debian";

let FAMILY_PATTERN_WINDOWS = /windows/i;
let FAMILY_PATTERN_LINUX = /linux/i;

const isWindowsPlatform = () => FAMILY_PATTERN_WINDOWS.test(platformFamily);
const isLinuxPlatform = () => FAMILY_PATTERN_LINUX.test(platformFamily);

const getOsPlatform = () => {
  switch (platformFamily) {
    case FAMILY_OSX:
      return "mac";
    case FAMILY_WINDOWS_NT:
    case FAMILY_WINDOWS:
      if (platformVersion=== "10" || platformVersion=== "11") {
        return "windows1011";
      } else {
        return "windows";
      }

    case FAMILY_LINUXI86:
      return "linux";
    case FAMILY_DEBIAN:
    case FAMILY_UBUNTU:
      return "debian_ubuntu";
    default:
      if (isWindowsPlatform()) {
        return "windows";
      } else if (isLinuxPlatform()) {
        return "linux";
      }
      return "other";
  }
};

export function fetchOs() {
  const osPlatform = getOsPlatform();
  switch (osPlatform) {
    case "windows1011":
      return {
        redirectUrl: environment["url.windows1011OsLauncher"],
      };
    case "windows":
      return {
        redirectUrl: environment["url.windowsOsLauncher"],
      };
    case "mac":
      return {
        redirectUrl: environment["url.MacOsLauncher"],
      };
    case "debian_ubuntu":
      return {
        redirectUrl: environment["url.debianUbuntuLauncher"],
      };
    case "linux":
      return {
        redirectUrl: environment["url.distributionLauncher"],
      };
    default:
      return {
        redirectUrl: environment["url.distributionLauncher"],
      };
  }
}
