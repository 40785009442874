import { action, observable } from "mobx";
import { minecraftServicesClient } from "../http";
import { session } from "@mojang/auth";
import { msaMigrationAPI, MSAMigrationAPI } from "../api/msaMigrationAPI";

export interface IMSAUpsellMigrationStore {
  state: IMSAMigrationStoreState;
  fetchMigrationData: () => void;
}

export interface IMSAMigrationStoreState {
  migrationData: string;
  fetching: boolean;
  error: boolean;
}

export class MSAUpsellMigrationStore {
  @observable
  public state: IMSAMigrationStoreState = {
    migrationData: null,
    fetching: true,
    error: false,
  };

  constructor(private api: MSAMigrationAPI) {}

  @action
  private setState(state: IMSAMigrationStoreState) {
    this.state = state;
  }

  public async fetchMigrationData() {
    this.setState({ ...this.state, error: false, fetching: true });
    const response = await this.api.migrationToken();

    this.setState({
      fetching: false,
      error: response.error,
      migrationData: response.data,
    });
  }
}

export const msaUpsellMigrationStore = new MSAUpsellMigrationStore(
  msaMigrationAPI(minecraftServicesClient, session)
);
