import { action, observable } from "mobx";
import {
  IMSAProfile,
  INameDetails,
  MSAProfileAPI,
  msaProfileAPI,
  IRolloutResponse
} from "./msaApi";
import { sharedEnvironment } from "@mojang/environment";
import { parseJwt, session } from "@mojang/auth";

export const msaProfileStoreSymbol: unique symbol = Symbol(
  "msaProfileStoreSymbol"
);
export interface ICapes {
  id: string;
  state: string;
  url: string;
  alias: string;
}
export interface IMSAProfileStore {
  state: IMSAProfileStoreState;
  fetchUserDetails: () => void;
}
export interface IMSAProfileStoreState {
  error: boolean;
  profile?: IMSAProfile;
  fetching: boolean;
  nameDetails: INameDetails;
  forced?:IRolloutResponse;
}

export class MSAProfileStore {
  @observable public state: IMSAProfileStoreState = {
    error: false,
    profile: null,
    fetching: false,
    nameDetails: undefined,
    forced: null
  };
  private static instance: MSAProfileStore;

  constructor(private api: MSAProfileAPI) {
    this.fetchUserDetails();
    this.forcedMigration();
  }

  static getInstance() {
    if (!MSAProfileStore.instance) {
      MSAProfileStore.instance = new MSAProfileStore(msaProfileAPI());
    }
    return MSAProfileStore.instance;
  }
  public async fetchUserDetails() {
    if (this.state.fetching) {
      return;
    }
    this.setState({ fetching: true });
    const { data, error } = await this.api.getProfile();

    this.setState({ fetching: false, profile: data, error: error });
    this.fetchNameDetails();
  }

  private fetchNameDetails = async () => {
    if (this.state.profile) {
      const { data, ok, error } = await this.api.getProfileNameDetails();
      this.setState({ nameDetails: ok ? data : undefined, error: error });
    }
  };
  public async forcedMigration() {
    const token = session.getToken();
    if(token!= ""){
      var isMsa = this.isMsaAccount(token);
      if(sharedEnvironment["enable.forcedMigration"] && (!isMsa)){ 
        const { data, error } = (await this.api.rollout("msamigrationforced"));
        this.setState({
          forced: data ? data : {"feature": "msamigrationforced","rollout": false},
          error: error
        });
        if (this.state.forced != null && this.state.forced.rollout) { sessionStorage.setItem('forcedMigrationEnabled', 'true'); }
        else { sessionStorage.setItem('forcedMigrationEnabled', 'false'); }
      }
    }
  }
  private isMsaAccount(token: string) {
    const oldTokenRegExp = RegExp(/^[0-9a-f]{32}$/);
    if (oldTokenRegExp.test(token)) {
      return false;
    }
    try {
      const json = parseJwt(token);
      if (json.yggt) {
        localStorage.setItem('LoginType', 'Mojang');
        return false;
      }
      else {
        localStorage.setItem('LoginType', 'MSA');
        return true;
      }
    } catch (e) {
      localStorage.setItem('LoginType', '');
      return false;
    }
  }

  @action
  private setState(state: Partial<IMSAProfileStoreState>) {
    this.state = { ...this.state, ...state };
  }
}
