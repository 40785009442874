import * as React from "react";
import { Button, IButtonProps } from "../button/Button";
import { Icon } from "../icon";

const KEYS = {
  down: "ArrowDown",
  up: "ArrowUp",
  enter: "Enter",
  space: "Space",
  esc: "Escape",
};

export interface IDropdownItem {
  name: string;
}

export interface IDropdownOnEnterSelectProps {
  onEnterSelect: (
    {
      e,
      selectedIndex,
      setSelectedIndex,
      closeDropdown,
    }: {
      e: KeyboardEvent;
      selectedIndex: number;
      setSelectedIndex: (value: React.SetStateAction<number>) => void;
      closeDropdown: () => void;
    }
  ) => void;
}

export interface IDropdownProps<T> {
  id: string;
  items: T[];
  itemRefs: React.RefObject<any>[];
  buttonTitle: string;
  isOpen?: boolean;
  ariaLabel?: string;
  buttonProps?: IButtonProps;
  className?: string;
  children?: (
    closeDropdown: () => void,
    setSelectedIndex: (value: React.SetStateAction<number>) => void
  ) => JSX.Element;
}

export function Dropdown<T extends IDropdownItem>({
  id,
  items,
  itemRefs,
  buttonTitle,
  children,
  isOpen = false,
  buttonProps,
  ariaLabel,
  onEnterSelect,
  ...rest
}: IDropdownProps<T> & IDropdownOnEnterSelectProps) {
  const maxIndex = items.length - 1;
  const [open, setOpen] = React.useState(isOpen);
  const buttonRef = React.useRef<HTMLButtonElement>();
  const dropdownRef = React.useRef<HTMLDivElement>();
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const toggleDropdown = (e: React.SyntheticEvent<EventTarget>) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const closeDropdown = () => {
    setOpen(false);
    buttonRef.current.focus();
  };

  React.useEffect(() => {
    if (itemRefs && open && itemRefs[selectedIndex]) {
      itemRefs[selectedIndex].current.focus();
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if (!open) {
        return;
      }

      switch (e.code) {
        case KEYS.down:
          e.preventDefault();
          setSelectedIndex(
            selectedIndex < maxIndex ? selectedIndex + 1 : maxIndex
          );
          break;
        case KEYS.up:
          e.preventDefault();
          setSelectedIndex(selectedIndex > 0 ? selectedIndex - 1 : 0);
          break;
        case KEYS.space:
        case KEYS.enter:
          onEnterSelect({ e, selectedIndex, setSelectedIndex, closeDropdown });
          break;
        case KEYS.esc:
          closeDropdown();
          break;
      }
    };

    const onMouseDown = (e: MouseEvent) => {
      if (!open) {
        return;
      }

      if (!dropdownRef.current.contains(e.target as Node)) {
        closeDropdown();
      }
    };

    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("keydown", onKeyDown);
    };
  });

  return (
    <div
      data-aem-contentname="Dropdown"
      className="dropdown"
      id={id}
      ref={dropdownRef}
      {...rest}
    >
      <Button
        data-aem-contentname="Dropdown Button"
        id={`${id}Button`}
        data-testid="dropdownButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded={open}
        aria-label={ariaLabel}
        ref={buttonRef}
        onClick={toggleDropdown}
        {...buttonProps}
      >
        <span
          className="text-small text-truncate"
          data-testid="dropdownChildren"
        />
        {buttonTitle}
        <Icon
          name="pixel-caret"
          className="ml-2"
          style={
            open
              ? { transition: ".25s", transform: "rotateX(180deg)" }
              : { transition: ".25s" }
          }
        />
      </Button>
      <div
        className={`dropdown-menu w-100 ${open ? "show" : ""}`}
        aria-labelledby={`${id}Button`}
        data-testid="dropdownMenu"
        onMouseMove={() => {
          itemRefs[selectedIndex].current.blur();
        }}
      >
        {children && children(closeDropdown, setSelectedIndex)}
      </div>
    </div>
  );
}
