import {
  classNameFromClasses,
  classNameFromVariant,
  classNameFromColorVariant,
} from "../css/cssHelpers";
import { IStyledButtonProps } from "./buttonProps";

export function parseButtonProps(
  rootClassName: string,
  props: IStyledButtonProps
) {
  const {
    block,
    colorVariant,
    size,
    shape,
    variant,
    className: classNameProp,
    ...rest
  } = props;
  const className = classNameFromClasses([
    rootClassName,
    classNameFromVariant(rootClassName, size),
    classNameFromVariant(rootClassName, variant),
    classNameFromVariant(rootClassName, shape),
    classNameFromColorVariant(rootClassName, colorVariant, variant),
    block ? classNameFromVariant(rootClassName, "block") : null,
    ...(classNameProp ? classNameProp.split(" ") : []),
  ]).className;
  return { ...rest, className };
}
