import * as React from "react";
import { getToken, hasBearer, setBearer, removeToken } from "./session";
import { parseJwt } from "./parseJwt";

const TIME_DIFF = 15 * 60;

interface ITokenPayload {
  agg: string;
  exp: number;
  iat: number;
  iss: string;
  nbf: number;
  sub: string;
}

interface ILocalTokenValidationProviderProps {
  tokenAuthenticator?: {
    valid: boolean;
    validating: boolean;
    error: boolean;
    validate: () => { valid: boolean; token: string };
  };
}

const defaultTokenAuthentication = {
  valid: false,
  validating: true,
  error: false,
  validate: validateJwt,
};

export interface ILocalTokenValidationConsumerProps {
  validating: boolean;
  retry: () => void;
  valid: boolean;
  error: boolean;
}

export const LocalTokenValidationContext = React.createContext<
  ILocalTokenValidationConsumerProps
>({
  validating: null,
  retry: () => undefined,
  valid: null,
  error: null,
});

export const LocalTokenValidationProvider: React.FunctionComponent<
  ILocalTokenValidationProviderProps
> = ({ tokenAuthenticator = defaultTokenAuthentication, children }) => {
  const { validating, valid, error, validate } = tokenAuthenticator;

  const [state, setState] = React.useState({
    valid,
    validating,
    error,
  });

  React.useEffect(
    () => {
      const { valid, token } = validate();

      setState({
        valid,
        validating: false,
        error: false,
      });
      if (valid && !hasBearer()) {
        // Can happen when old cookie is set and valid
        setBearer(token);
      }

      if (!valid) {
        removeToken();
      }
    },
    // We can't have state.validating and validate as dependencies, it causes an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <LocalTokenValidationContext.Provider
      value={{
        ...state,
        retry: () => {},
      }}
    >
      {children}
    </LocalTokenValidationContext.Provider>
  );
};

function validateJwt() {
  const token = getToken();
  const now = Math.round(new Date().getTime() / 1000);
  const tokenData = parseJwt(token) as ITokenPayload;
  const valid = tokenData && tokenData.exp - now > TIME_DIFF;

  return { valid, token };
}
