import { IFontStyleProps } from "./ITextElementProps";
export function fontStyleToClassName(props: IFontStyleProps) {
  const { fontStyle } = props;
  const styles = Array.isArray(fontStyle)
    ? fontStyle
    : fontStyle
    ? [fontStyle]
    : [];
  return styles.map(style => `font-${style}`).join(" ");
}
