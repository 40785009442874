import * as React from "react";
import { accountTypeFromToken } from "./accountTypeFromToken";
import { AccountType } from "./AccountType";

export interface IAccountTypeConsumerProps {
  accountType: AccountType;
}

const defaultContext: IAccountTypeConsumerProps = {
  accountType: AccountType.UNKNOWN,
};

export const AccountTypeContext = React.createContext<
  IAccountTypeConsumerProps
>(defaultContext);

interface IAccountTypeProviderProps {
  token: string;
}

export const AccountTypeProvider: React.FunctionComponent<
  IAccountTypeProviderProps
> = ({ children, token }) => (
  <AccountTypeContext.Provider
    value={{ accountType: accountTypeFromToken(token) }}
  >
    {children}
  </AccountTypeContext.Provider>
);
