import {
  NOT_SET,
  validate,
  ISharedEnvironment,
  sharedDefaultEnvironment,
  sharedEnvironment,
} from "@mojang/environment";

declare global {
  interface Window {
    accountNavMenuConfig: IAccountNavMenuEnvironment;
  }
}

export interface IAccountNavMenuEnvironment extends ISharedEnvironment {
  translationPath: string;
  "url.verifyEmail"?: string;
  "url.orderHistory"?: string;
}

const defaultAccountNavMenuEnvironment: IAccountNavMenuEnvironment = {
  translationPath: NOT_SET,
  "url.verifyEmail": null,
  "url.orderHistory": null,
  ...sharedDefaultEnvironment,
};

const env: IAccountNavMenuEnvironment = {
  ...defaultAccountNavMenuEnvironment,
  ...sharedEnvironment,
  ...window.accountNavMenuConfig,
};

export const environment = validate(env, "accountNavMenuConfig");
