import { action, observable } from "mobx";
import { xboxLiveService } from "../http/";
import { XboxLiveAPI, xboxLiveApi } from "../api/xboxLiveApi";

export interface IMSAXboxRedirectUrlStore {
  state: MSAXboxRedirectUrlStoreState;
  fetchRedirectUrl: (profileName: string, callbackUrl: string) => void;
}

export interface MSAXboxRedirectUrlStoreState {
  xboxRedirectUrl: string;
  fetching: boolean;
  error: boolean;
}

export class MSAXboxRedirectUrlStore {
  @observable state: MSAXboxRedirectUrlStoreState = {
    xboxRedirectUrl: null,
    fetching: true,
    error: false,
  };

  constructor(private api: XboxLiveAPI) {}

  public async fetchRedirectUrl(profileName: string, callbackUrl: string) {
    this.setState({
      ...this.state,
      error: false,
      fetching: true,
    });

    const { error, data } = await this.api.getRedirectUrl(
      profileName,
      callbackUrl
    );

    this.setState({
      xboxRedirectUrl:
        data && data.RedirectTo
          ? `${data.RedirectTo}&signup=upgrade`
          : undefined,
      error,
      fetching: false,
    });
  }

  @action
  private setState(state: MSAXboxRedirectUrlStoreState) {
    this.state = state;
  }
}

export const msaXboxRedirectUrlStore = new MSAXboxRedirectUrlStore(
  xboxLiveApi(xboxLiveService)
);
