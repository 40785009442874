import * as React from "react";
import "string-polyfills";
import { CountdownView } from "./CountdownView";

interface ICountdownProps {
  time: number;
  className?: string;
}

export function tokenize(time: number) {
  return time
    .toString()
    .padStart(2, "0")
    .substr(0, 3)
    .split("");
}

const toSeconds = 1000;
const toMinutes = 60;
const toHours = 60;
const toDays = 24;

export function formatTime(time: Date) {
  const seconds = time.getUTCSeconds();
  const minutes = time.getUTCMinutes();
  const hours = time.getUTCHours();
  const days = Math.floor(
    time.getTime() / (toSeconds * toMinutes * toHours * toDays)
  );

  return {
    days: tokenize(days),
    hours: tokenize(hours),
    minutes: tokenize(minutes),
    seconds: tokenize(seconds),
  };
}

export const Countdown = ({ time }: ICountdownProps) => (
  <CountdownView time={formatTime(new Date(time))} />
);
