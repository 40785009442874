import { IGenericResponse } from "./IGenericResponse";
import { IHttpClient } from "./IHttpClient";

export class FetchHttpClient implements IHttpClient {
  constructor(
    private endpoint: string,
    private init?: RequestInit,
    private handleReject?: (error: any) => void
  ) {}

  getJson = async <T>(path: string, init?: RequestInit): Promise<T> => {
    const res = await this.get(path, init);
    return (await res.json()) as T;
  };

  get = async <T>(path: string, init?: RequestInit) => {
    return await this.request<T>(path, "GET", null, init);
  };

  delete = async <T>(path: string, init?: RequestInit) => {
    return await this.request<T>(path, "DELETE", null, init);
  };

  post = async <T>(
    path: string,
    data?: object | FormData | string,
    init?: RequestInit
  ) => {
    return await this.request<T>(path, "POST", data, init);
  };

  put = async <T>(
    path: string,
    data?: object | FormData | string,
    init?: RequestInit
  ) => {
    return await this.request<T>(path, "PUT", data, init);
  };

  request = async <T>(
    path: string,
    method: string,
    data?: object | FormData | string,
    init?: RequestInit
  ) => {
    const temp = {
      ...(this.init && this.init.headers),
      ...(init && init.headers),
    };

    const headers = Object.keys(temp).length === 0 ? null : { headers: temp };

    const fetchInit = {
      ...this.init,
      ...init,
      ...headers,
      method,
    } as RequestInit;

    let body = data;
    if (!(data instanceof FormData) && !(typeof data === "string")) {
      body = data && JSON.stringify(data);
    }

    if (body) {
      fetchInit.body = body as BodyInit;
    }

    try {
      return (await fetch(
        `${this.endpoint}${path}`,
        fetchInit
      )) as IGenericResponse<T>;
    } catch (error) {
      if (this.handleReject) {
        this.handleReject(error);
      } else {
        // throw the caught error to keep backwards compatibility with the cases where this error was handled more specifically
        throw error;
      }
    }
  };
}
