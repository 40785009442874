import { FetchHttpClient } from "@mojang/http";
import { sharedEnvironment } from "@mojang/environment";
import { getToken } from "@mojang/auth";
export const minecraftServicesClient = new FetchHttpClient(
  sharedEnvironment["endpoint.minecraftServices"],
  {
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    mode: "cors",
    redirect: "follow",
  }
);
