import * as React from "react";
import {
  buildMsaLoginUrl,
  IBuildMsaLoginUrlParams,
} from "../utils/buildMsaLoginUrl";
import { AreaOverlay, Loader } from "@mojang/components";
import { T } from "@mojang/t-component";
export interface IMSALoginRedirectProps {
  returnURL?: string;
  xboxState?: string;
}

export const MSALoginRedirect = ({
  returnURL = "",
  xboxState = "",
}: IMSALoginRedirectProps) => {
  const param: IBuildMsaLoginUrlParams = {
    returnURL,
    xboxState,
  };
  React.useEffect(() => {
    window.location.replace(buildMsaLoginUrl(window.location.search, param));
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AreaOverlay>
      <Loader>
        <T>Logging in...</T>
      </Loader>
    </AreaOverlay>
  );
};
