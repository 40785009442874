import { AccountType } from "../AccountType";
import { parseJwt } from "../../utils/parseJwt";

export const jwtTokenStrategy = (token: string, parseToken = parseJwt) => {
  try {
    const json = parseToken(token);
    return json.yggt ? AccountType.MOJANG : AccountType.MSA;
  } catch (e) {
    return AccountType.UNKNOWN;
  }
};
