import { environment } from "../environment";
import { getEnvironmentParam } from "./getEnvironmentParam";

export interface IBuildMsaLoginUrlParams {
  returnURL?: string;
  xboxState?: string;
}
/**
 * Returns an MSA SISU login URL to redirect to.
 * @param search A string containing query params of the return url
 */
export function buildMsaLoginUrl(
  search: string,
  param: IBuildMsaLoginUrlParams = {}
) {
  const state = param.xboxState
    ? param.xboxState
    : environment["msaStateLoginParam"];
  const callbackUrl = param.returnURL
    ? param.returnURL
    : environment["url.xboxLoginReturnUrl"];
  const envName = getEnvironmentParam(search, callbackUrl);
  const ru = encodeURIComponent(callbackUrl + search + envName);
  const params = [
    `state=${state}`,
    `cobrandId=${environment.sisuCobrandId}`,
    environment["xboxSisuTestParam"],
    `tid=${environment.titleId}`,
    `ru=${ru}`,
    `aid=${environment.sisuAppId}`,
  ]
    .filter(Boolean)
    .join("&");
  return `${environment["endpoint.xboxAuthUrl"]}?${params}`;
}

window.buildMsaLoginUrl = buildMsaLoginUrl;

export function buildMsaRegisterUrl(search: string) {
  const envName = getEnvironmentParam(search);
  const ru = environment["url.xboxRegisterReturnUrl"] + search + envName;
  const params = [
    "state=signup",
    "signup=1",
    `cobrandId=${environment.sisuCobrandId}`,
    environment["xboxSisuTestParam"],
    `tid=${environment.titleId}`,
    `ru=${encodeURI(ru)}`,
    `aid=${environment.sisuAppId}`,
  ].join("&");

  return `${environment["endpoint.xboxAuthUrl"]}?${params}`;
}
