import * as React from "react";
import { classNameFromClasses } from "../css/cssHelpers";
import { ITextElementProps } from "./ITextElementProps";
import { textElementPropsToClassName } from "./textElementPropsToClassName";

export interface IParagraphProps extends ITextElementProps {
  id?: string;
}

export const Paragraph: React.StatelessComponent<IParagraphProps> = ({
  className: cssClass,
  fontStyle,
  align,
  color,
  display,
  ...rest
}) => {
  const className = classNameFromClasses([
    cssClass,
    textElementPropsToClassName({ fontStyle, align, color, display }),
  ]);

  return <p {...className} {...rest} />;
};
