import { environment } from "./environment";
import { parseAccessToken } from "./utils/parseJwt";

export interface AccessTokenItem {
  Item1: string;
  Item2: {
    DisplayClaims?: {
      xui?: xui[];
    };
    IssueInstant?: string;
    NotAfter?: string;
    Token?: string;
  };
}

type xui = {
  [key: string]: string;
};

export const getXboxAccessToken = (hash: string) => {
  return new URLSearchParams(hash.replace("#", "?")).get("accessToken");
};

export const decodeXboxAccessTokens = (accessToken: string) => {
  try {
    // const decoded = window.atob(accessToken);
    // return JSON.parse(decoded) as AccessTokenItem[];
    return parseAccessToken(accessToken) as AccessTokenItem[];
  } catch (error) {
    return;
  }
};

export const getXboxGamerTag = (tokens: AccessTokenItem[]) => {
  let gamerTag = "Unknown";
  try {
    const xui = tokens?.find((i: AccessTokenItem) =>
      i.Item1.includes("http://xboxlive.com")
    )?.Item2?.DisplayClaims?.xui;

    xui?.forEach((x) => {
      Object.entries(x).forEach(([key, val]) => {
        if (key === "gtg") gamerTag = val;
      });
    });
  } catch (e) {
    console.log(e);
  }
  return gamerTag;
};

export const getXboxIdentityToken = (tokens: AccessTokenItem[]) => {
  try {
    return tokens.find((i: AccessTokenItem) =>
      i.Item1.includes(environment.relyingPartyKey)
    )?.Item2.Token;
  } catch (e) {
    return;
  }
};

export const getXboxIdentityTokenWithUhs = (tokens?: AccessTokenItem[]) => {
  try {
    const token = tokens?.find((i: AccessTokenItem) =>
      i.Item1.includes(environment.relyingPartyKey)
    )?.Item2;
    if (token) {
      return `XBL3.0 x=${token?.DisplayClaims?.xui?.[0].uhs};${token?.Token}`;
    }
  } catch (e) {
    return;
  }
};

export const getIdentityToken = () => {
  const xboxAccessToken = getXboxAccessToken(window.location.hash);
  const decodedTokens = decodeXboxAccessTokens(xboxAccessToken || "");
  const idToken = getXboxIdentityTokenWithUhs(decodedTokens);
  return idToken;
};

/**

 * This function is used to convert a decoded access token into a requested XBL token.

 * @param XBLType Will receive Xbox or playfab URL.

 * @param tokens Will receive the decoded access token.

 * @returns XBL Token

 */

export const getXBLToken = (XBLType: string, tokens?: AccessTokenItem[]) => {
  try {

    const token = tokens?.find((i: AccessTokenItem) =>
      i.Item1.includes(XBLType)
    )?.Item2;
    if (token) {
      return `XBL3.0 x=${token?.DisplayClaims?.xui?.[0].uhs};${token?.Token}`;
    }

  } catch (e) {
    return;
  }

};
