import { environment } from "../environment";
import {
  decodeXboxAccessTokens,
  getXboxAccessToken,
  getXboxGamerTag,
  getXBLToken
} from "../xboxToken";
import { session } from "@mojang/auth";

const accessToken = decodeXboxAccessTokens(
  getXboxAccessToken(window.location.hash) || ""
);


const xbox = environment["url.xboxLiveUrl"]
const playFab = environment["url.plafabXboxLiveUrl"];
const marketplaceMinecraft = environment["url.marketplaceMinecraft"];
const pocketRealms = environment["url.pocketRelamsMinecraft"];

const gamerTag = getXboxGamerTag(accessToken || []);

export function setSession(access_token: string, userId: string) {
  session.setToken({
    user: {
      id: userId,
      username: gamerTag,
    },
    accessToken: access_token,
    clientToken: "",
    availableProfiles: [],
  });

  localStorage.setItem('XboxXBLToken', getXBLToken(xbox, accessToken));
  localStorage.setItem('PlayFabXBLToken', getXBLToken(playFab, accessToken));
  localStorage.setItem('MarketplaceXBLToken', getXBLToken(marketplaceMinecraft, accessToken));
  localStorage.setItem('RealmsXBLToken', getXBLToken(pocketRealms, accessToken));
}
