import { minecraftServicesClient } from "../http";

export interface Items {
  name: string;
  signature: string;
}

export interface IMSAEntitlement {
  items: Items[];
  signature: string;
  keyId: string;
}

export type GetEntitlementFunction = (
  access_token: string
) =>
  | Promise<{ error: boolean; data?: IMSAEntitlement | undefined }>
  | { error: boolean; data?: IMSAEntitlement | undefined };

export const getEntitlement: GetEntitlementFunction = async (
  accessToken: string
) => {
  try {
    const userResponse = await minecraftServicesClient.get<IMSAEntitlement>(
      "entitlements",
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    if (userResponse.ok) {
      return { data: await userResponse.json(), error: false };
    }
  } catch (error) {
    return { error: true };
  }
  return { data: null, error: false };
};
