import * as React from "react";
import { Button } from "..";

interface IInlineConfirmationProps {
  triggerButtonText: string;
  confirmButton: IInlineConfirmationButton;
  cancelButton: IInlineConfirmationButton;
}

interface IInlineConfirmationButton {
  label: string;
  onClick?: () => void;
}

export const InlineConfirmation = ({
  triggerButtonText,
  confirmButton,
  cancelButton,
}: IInlineConfirmationProps) => {
  const [questionsVisible, setQuestionsVisible] = React.useState(false);

  const handleConfirm = () => {
    setQuestionsVisible(false);
    confirmButton.onClick && confirmButton.onClick();
  };

  const handleCancel = () => {
    setQuestionsVisible(false);
    cancelButton.onClick && cancelButton.onClick();
  };

  return (
    <React.Fragment>
      <Button
        variant="link"
        className={`${questionsVisible ? "d-none" : ""}`}
        onClick={() => setQuestionsVisible(true)}
      >
        {triggerButtonText}
      </Button>
      <div
        className={`${questionsVisible ? "" : "d-none"}`}
        data-testid="inline-confirmation-buttons"
      >
        <Button
          data-aem-contentname="Inline confirm button"
          colorVariant="primary"
          onClick={handleConfirm}
          size="sm"
          className="py-2 px-3 text-smaller text-nowrap"
          data-testid="confirm-action"
        >
          {confirmButton.label}
        </Button>
        <Button
          variant="link"
          onClick={handleCancel}
          size="sm"
          className="ml-1"
          data-testid="cancel-action"
        >
          {cancelButton.label}
        </Button>
      </div>
    </React.Fragment>
  );
};
