import { session } from "@mojang/auth";
import { IHttpClient,IGenericResponse } from "@mojang/http";
import { minecraftServicesClient } from "./minecraftServicesClient";
import { ICapes } from "./MSAProfileStore";

export interface IMSAProfile {
  id: string;
  name: string;
  skins: [
    {
      id: string;
      state: string;
      url: string;
      variant: string;
      alias: string;
    }
  ];
  capes: ICapes[];
}
//TODO: Rename this to IProfileNameDetails once old is deleted from /minecraft-profile/api,ts
export interface INameDetails {
  createdAt: number;
  changedAt: number;
  nameChangeAllowed: boolean;
}

export interface ILookUpName {
  status: string;
}
export interface IRolloutResponse {
  feature: string;
  rollout: boolean;
}
export type MSAProfileAPI = {
  getProfile: () => Promise<{ data: IMSAProfile; error: boolean }>;
  createProfileName: (
    name: string
  ) => Promise<{ ok: boolean; data: IMSAProfile; error: boolean }>;
  lookupProfileName: (
    name: string
  ) => Promise<{ data: ILookUpName; error: boolean }>;
  changeProfileName: (
    name: string
  ) => Promise<{ ok: boolean; data: IMSAProfile; error: boolean }>;
  getProfileNameDetails: () => Promise<{
    data: INameDetails | undefined;
    ok: boolean;
    error: boolean;
  }>;
  rollout: (
    featureName: string
  ) => Promise<{ data: IRolloutResponse; error: boolean }>;
};

export function msaProfileAPI(
  http: IHttpClient = minecraftServicesClient
): MSAProfileAPI {
  return {
    getProfile: async function getProfile() {
      const token = session.getToken();
      try {
        const response = await http.get<IMSAProfile>("minecraft/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          return { data: await response.json(), error: false };
        }
        if (response.status === 404) {
          return { data: null, error: false };
        }
      } catch (error) {
        return { data: null, error: true };
      }

      return { data: null, error: true };
    },
    lookupProfileName: async function lookupProfileName(name) {
      try {
        const response = await http.get<ILookUpName>(
          `minecraft/profile/name/${name}/available`
        );
        if (response.status === 200) {
          return { data: await response.json(), error: false };
        }
      } catch (error) {
        return { data: null, error: true };
      }
      return { data: null, error: false };
    },
    createProfileName: async function createProfileName(name) {
      try {
        const body = {
          profileName: name,
        };
        const response = await http.post<IMSAProfile>(
          "minecraft/profile",
          JSON.stringify(body),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          return { ok: response.ok, data: await response.json(), error: false };
        }
      } catch (error) {
        return { ok: false, data: null, error: true };
      }

      return { ok: false, data: null, error: false };
    },
    changeProfileName: async function changeProfileName(name) {
      try {
        const response = await http.put<IMSAProfile>(
          `minecraft/profile/name/${name}`
        );

        if (response.ok) {
          return { ok: response.ok, data: await response.json(), error: false };
        }
      } catch (error) {
        return { ok: false, data: null, error: true };
      }

      return { ok: false, data: null, error: false };
    },
    getProfileNameDetails: async function getProfileNameDetails() {
      try {
        const response = await http.get<INameDetails>(
          "minecraft/profile/namechange"
        );

        if (response.ok) {
          const data = await response.json();
          return { data, ok: true, error: false };
        }
      } catch (error) {
        return { data: undefined, ok: false, error: true };
      }

      return { data: undefined, ok: false, error: false };
    },
    rollout: async function rollout(featureName: string) {
      const token = session.getToken(); 
      try {
        const response: IGenericResponse<IRolloutResponse> = await http.get(`rollout/v1/${featureName}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          return { data,  error: false };
        }
      } catch (error) {
        return { data: null, error: true };
      }
      return { data: undefined, error: false };
    }
  };
}
