export interface AsyncData<T> {
  fetch: boolean;
  data?: T;
  timer_ended: boolean;
}

export interface AsyncFunction<T> extends Function {
  (): PromiseLike<AsyncData<T>>;
}

export async function asyncPoll<T>(
  fn: AsyncFunction<T>,
  pollInterval: number = 2 * 1000,
  pollTimeout: number = 15 * 1000
): Promise<T> {
  const endTime = new Date().getTime() + pollTimeout;

  const runPolling = (resolve: Function, reject: Function): void => {
    Promise.resolve(fn())
      .then(result => {
        const now = new Date().getTime();
        if (result.fetch === true) {
          resolve(result);
        } else if (now < endTime) {
          setTimeout(runPolling, pollInterval, resolve, reject);
        } else {
          resolve({ ...result, timer_ended: true, fetch: true });
        }
      })
      .catch(err => {
        reject(err);
      });
  };

  return new Promise(runPolling);
}
