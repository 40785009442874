import * as React from "react";
import { ILogoutStore } from "@mojang/logout";
import { GetSessionUsername } from "@mojang/auth";
import { AccountNavMenuDropdown } from "./AccountNavMenuDropdown";
import {
  AccountStore,
  useStore,
  accountStoreSymbol,
} from "@mojang/stores-provider";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "@mojang/t-component";

interface IAccountNavMenuApp {
  logoutStore: ILogoutStore;
  getSessionUsername: GetSessionUsername;
  itemUrls: {
    login: string;
    profile: string;
    orderHistory: string;
    verifyEmail: string;
  };
}

export const AccountNavMenuApp = ({
  logoutStore,
  getSessionUsername,
  itemUrls,
}: IAccountNavMenuApp) => {
  const { t } = useTranslation();
  const accountStore = useStore<AccountStore>(accountStoreSymbol);

  const initialItems = [
    { name: t("Profile"), href: itemUrls.profile },
    { name: t("Order History"), href: itemUrls.orderHistory || null },
    { name: t("Log out") },
  ].filter(i => i.href !== null);

  return useObserver(() => {
    const { fetching, userDetails } = accountStore.state;
    if (!fetching && userDetails && getSessionUsername()) {
      const emailVerified = userDetails.emailVerified;
      const emailVerifiedItem = [
        {
          name: t("Verify this account"),
          href: itemUrls.verifyEmail,
        },
      ];
      const dropdownItems = [
        ...(!emailVerified ? emailVerifiedItem : []),
        ...initialItems,
      ];

      return (
        <AccountNavMenuDropdown
          logoutStore={logoutStore}
          items={dropdownItems}
          sessionUsername={getSessionUsername()}
        />
      );
    } else {
      return null;
    }
  });
};
