import * as Cookies from "es-cookie";
import { sharedEnvironment } from "@mojang/environment";
import { IAuthorizationDetails } from "./IAuthorizationDetails";
import { ICookieOptions } from "./ICookieOptions";
import { cleanupObjFields } from "./utils";

const accessCookieKey = "access_token";
const bearerCookieKey = "bearer_token";
const sessionUsernameCookieKey = "session_username";

type SetToken = (
  accessToken: IAuthorizationDetails | null,
  cookieName?: string,
  options?: ICookieOptions
) => void;

export type RemoveToken = (
  cookieName?: string,
  options?: ICookieOptions
) => void;

export type GetToken = (cookieName?: string) => string;

export type GetSessionUsername = () => string;

export const setToken: SetToken = (
  accessCookie,
  cookieName = sharedEnvironment["cookie.name"],
  options = sharedEnvironment["cookie.options"]
) => {
  Cookies.set(
    cookieName || bearerCookieKey,
    accessCookie.accessToken,
    cleanupObjFields(options)
  );
  Cookies.set(
    accessCookieKey,
    JSON.stringify(accessCookie),
    cleanupObjFields(options)
  );
  Cookies.set(
    sessionUsernameCookieKey,
    accessCookie.user.username,
    cleanupObjFields(options)
  );
};

export const removeToken: RemoveToken = (
  cookieName = sharedEnvironment["cookie.name"],
  options = sharedEnvironment["cookie.options"]
) => {
  Cookies.remove(cookieName || bearerCookieKey, cleanupObjFields(options));
  Cookies.remove(accessCookieKey, cleanupObjFields(options));
  Cookies.remove(sessionUsernameCookieKey, cleanupObjFields(options));
  Cookies.remove(
    sharedEnvironment["cookie.entitlementState"],
    cleanupObjFields(options)
  );
  localStorage.removeItem('XboxXBLToken');
  localStorage.removeItem('PlayFabXBLToken');
  localStorage.removeItem('MarketplaceXBLToken');
  localStorage.removeItem('RealmsXBLToken');
  
  window.addEventListener("pageshow", event => {
    if (event.persisted) {
      window.location.reload();
    }
  });
};

export const getToken: GetToken = (
  cookieName = sharedEnvironment["cookie.name"]
) => {
  const token = Cookies.get(cookieName || bearerCookieKey);
  if (token) {
    return token;
  }

  // Fallback if new cookie is not set.
  // Can be removed after we have migrated over to the new cookie name.
  const cookie = Cookies.get(accessCookieKey);
  const result = cookie && (JSON.parse(cookie) as IAuthorizationDetails);
  return result ? result.accessToken : "";
};

export const getSessionUsername: GetSessionUsername = () =>
  Cookies.get(sessionUsernameCookieKey);

export type Session = {
  getToken: GetToken;
  setToken: SetToken;
  removeToken: RemoveToken;
};

export const session: Session = {
  getToken,
  setToken,
  removeToken,
};

type HasBearer = (cookieName?: string) => boolean;

export const hasBearer: HasBearer = (
  cookieName = sharedEnvironment["cookie.name"]
) => !!Cookies.get(cookieName);

type SetBearer = (
  accessToken: string,
  cookieName?: string,
  options?: ICookieOptions
) => void;

export const setBearer: SetBearer = (
  accessToken,
  cookieName = sharedEnvironment["cookie.name"],
  options = sharedEnvironment["cookie.options"]
) => {
  Cookies.set(
    cookieName || bearerCookieKey,
    accessToken,
    cleanupObjFields(options)
  );
};
