import { FetchHttpClient } from "@mojang/http";
import { sharedEnvironment } from "@mojang/environment";

export const authService = new FetchHttpClient(
  sharedEnvironment["endpoint.authService"],
  {
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    mode: "cors",
    redirect: "follow",
  }
);
