import { IHttpClient } from "@mojang/http";

interface ILoginWithXboxResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  roles: Array<unknown>;
  token_type: "Bearer";
  username: string;
}

export enum Errors {
  RegularError,
  NetworkError,
}

export type XboxAPI = ReturnType<typeof xboxAPI>;

export function xboxAPI(http: IHttpClient) {
  return {
    loginWithXbox: async function loginWithXbox(identityToken: string) {
      try {
        const response = await http.post<ILoginWithXboxResponse>(
          "authentication/login_with_xbox",
          {
            identityToken,
            ensureLegacyEnabled: true,
          }
        );
        return { ok: response.ok, error: false, data: await response.json() };
      } catch (error) {
        return { ok: false, error: true, data: null };
      }
    },
  };
}
