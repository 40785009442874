import { IHttpClient, IGenericResponse } from "@mojang/http";
import { Session, getSessionUsername } from "@mojang/auth";

export interface IMigrationErrorDetails {
  error: string;
  errorType: string;
  path: string;
}
interface ILoginWithXboxResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  roles: Array<any>;
  token_type: "Bearer";
  username: string;
}
interface IRolloutResponse {
  feature: string;
  rollout: boolean;
}
interface ILinkWithXboxResponse {
  status: string;
}

export enum Errors {
  RegularError,
  NetworkError,
}

export type MSAMigrationAPI = {
  linkWithXbox: (
    identityToken: string,
    otpId: string
  ) => Promise<{
    ok: boolean;
    error: boolean;
    migrationError: IMigrationErrorDetails;
    retryable?: boolean;
  }>;
  loginWithXbox: (
    identityToken: string
  ) => Promise<{
    ok: boolean;
    error: boolean;
    data: ILoginWithXboxResponse;
  }>;
  migrationToken: () => Promise<{
    error: boolean;
    data: any;
  }>;
  rollout: (
    featureName: string
  ) => Promise<{ data: IRolloutResponse; error: boolean }>;
  createShadowAccount: (
    identityToken: string
  ) => Promise<{
    error?: Errors;
    data: any;
  }>;

  migrationStatus: () => Promise<{
    ok: boolean;
    error: boolean;
    data: ILinkWithXboxResponse;
    statusCode: number;
  }>;
  validateMigration: (
    identityToken: string
  ) => Promise<{
    error: boolean;
    ok: any;
    migrationError: IMigrationErrorDetails;
    retryable?: boolean;
  }>;
};

export function msaMigrationAPI(
  http: IHttpClient,
  session: Session
): MSAMigrationAPI {
  return {
    linkWithXbox: async function linkWithXbox(
      identityToken: string,
      otpId: string
    ) {
      const token = await session.getToken();
      let response: IGenericResponse<IMigrationErrorDetails>;
      let body = {
        identityToken: identityToken,
        otpId: otpId,
      };
      try {
        response = await http.post("migration/link", JSON.stringify(body), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 202) {
          return {
            error: false,
            ok: true,
            migrationError: null,
          };
        }
        if (response.status === 409) {
          return {
            error: false,
            ok: false,
            migrationError: await response.json(),
          };
        }
        if (response.status % 500 < 10) {
          return {
            error: true,
            migrationError: null,
            ok: false,
            retryable: true,
          };
        }
      } catch (error) {
        return {
          error: true,
          retryable: false,
          ok: false,
          migrationError: null,
        };
      }
      return {
        error: true,
        ok: false,
        migrationError: null,
        retryable: false,
      };
    },
    loginWithXbox: async function loginWithXbox(identityToken: string) {
      let response: IGenericResponse<ILoginWithXboxResponse>;
      try {
        response = await http.post("authentication/login_with_xbox", {
          identityToken,
          ensureLegacyEnabled: true,
        });
      } catch (error) {
        return { ok: false, error: true, data: null };
      }

      return { ok: response.ok, error: false, data: await response.json() };
    },
    migrationToken: async function migrationToken() {
      const token = session.getToken();
      let response: IGenericResponse<string>;
      const body = { sessionEmail: getSessionUsername() };
      try {
        response = await http.post<string>(
          "migration/token",
          JSON.stringify(body),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        return { error: true, data: null };
      }

      return {
        error: !response.ok,
        data: response.ok ? await response.text() : null,
      };
    },
    rollout: async function rollout(featureName: string) {
      const token = session.getToken();
      let response: IGenericResponse<IRolloutResponse>;

      try {
        response = await http.get(`rollout/v1/${featureName}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        return { data: null, error: true };
      }

      return {
        data: await response.json(),
        error: !response.ok,
      };
    },
    createShadowAccount: async function createShadowAccount(xboxToken: string) {
      let response: IGenericResponse<any>;
      try {
        response = await http.post("registration/createAccount", { xboxToken });
      } catch (error) {
        return { error: Errors.NetworkError, data: undefined };
      }

      if (response.status == 400) {
        const json = await response.json();
        const userAlreadyCreated =
          json.developerMessage == "User Already Registered";
        return {
          error: userAlreadyCreated ? undefined : Errors.RegularError,
          data: undefined,
        };
      }

      return {
        error: response.ok ? undefined : Errors.RegularError,
        data: undefined,
      };
    },

    migrationStatus: async function migrationStatus() {
      const token = await session.getToken();
      let response: IGenericResponse<any>;
      try {
        response = await http.get("migration/status", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          return {
            error: !response.ok,
            data: await response.json(),
            ok: response.ok,
            statusCode: response.status,
          };
        }
        if (response.status === 404) {
          return {
            error: false,
            data: null,
            ok: response.ok,
            statusCode: response.status,
          };
        }
      } catch (error) {
        return {
          error: true,
          data: null,
          ok: false,
          statusCode: null,
        };
      }

      return {
        error: true,
        data: null,
        ok: false,
        statusCode: response.status,
      };
    },
    validateMigration: async function validateMigration(identityToken: string) {
      const token = await session.getToken();
      let response: IGenericResponse<IMigrationErrorDetails>;
      try {
        response = await http.post(
          "migration/validate",
          { identityToken },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 204) {
          return {
            error: !response.ok,
            migrationError: null,
            ok: response.ok,
          };
        }
        if (response.status === 409) {
          return {
            error: false,
            migrationError: await response.json(),
            ok: false,
          };
        }
        if (response.status % 500 < 10) {
          return {
            error: true,
            migrationError: null,
            ok: false,
            retryable: true,
          };
        }
      } catch (error) {
        return {
          error: true,
          retryable: false,
          migrationError: null,
          ok: false,
        };
      }

      return {
        error: true,
        retryable: false,
        migrationError: null,
        ok: false,
      };
    },
  };
}
