import { observable, action } from "mobx";
import {
  IMigrationErrorDetails,
  msaMigrationAPI,
  MSAMigrationAPI,
} from "../api/msaMigrationAPI";
import { Session, session } from "@mojang/auth";
import { minecraftServicesClient } from "../http";
import { buildMsaLoginUrl } from "@mojang/msa-utils";
import { asyncPoll, AsyncData } from "./apiPolling";

export const msaAccountLinkStoreSymbol: unique symbol = Symbol(
  "mSAccountLinkStoreSymbol"
);
export enum errorType {
  SOURCE_ACCOUNT_MIGRATED = "SOURCE_ACCOUNT_MIGRATED",
  TARGET_ACCOUNT_ENTITLED = "TARGET_ACCOUNT_ENTITLED",
  NOT_ELIGIBLE = "NOT_ELIGIBLE",
  MIGRATION_IN_PROGRESS = "MIGRATION_IN_PROGRESS",
  PREVIOUS_MIGRATION_FAILED = "PREVIOUS_MIGRATION_FAILED",
  INVALID_MFA_CODE = "INVALID_MFA_CODE",
}

const statusResponse = {
  complete: "COMPLETE",
  inProgress: "IN_PROGRESS",
  error: "ERROR",
};

export interface IMSAAccountLinkStoreState {
  ok: boolean;
  error: boolean;
  linking: boolean;
  success: boolean;
  validating: boolean;
  migrationError: IMigrationErrorDetails | null;
  retryable: boolean;
}

export interface IMSAAccountLinkStore {
  readonly state: IMSAAccountLinkStoreState;
  linkWithXbox: (xboxToken: string) => void;
  checkMigrationStatus: () => void;
  validateMigration: (xboxToken: string) => void;
}

export class MSAAccountLinkStore implements IMSAAccountLinkStore {
  @observable public state: IMSAAccountLinkStoreState = {
    ok: false,
    error: false,
    linking: false,
    success: false,
    validating: false,
    migrationError: null,
    retryable: false,
  };

  constructor(
    private api: MSAMigrationAPI,
    private session: Session,
    private location: Location
  ) {}

  checkMigrationStatus = async (): Promise<AsyncData<any>> => {
    try {
      const { ok, error, data, statusCode } = await this.api.migrationStatus();

      if (ok) {
        if (data.status === statusResponse.complete) {
          return Promise.resolve({
            fetch: true,
            data: data.status,
            timer_ended: false,
          });
        }
        if (data.status === statusResponse.inProgress) {
          return Promise.resolve({
            fetch: false,
            data: data.status,
            timer_ended: false,
          });
        }
        if (data.status === statusResponse.error) {
          return Promise.resolve({
            fetch: true,
            data: data.status,
            timer_ended: false,
          });
        }
      }

      if (statusCode === 404) {
        return Promise.resolve({
          fetch: false,
          data: statusResponse.inProgress,
          timer_ended: false,
        });
      }

      if (error) {
        return Promise.resolve({
          fetch: true,
          data: null,
          timer_ended: false,
        });
      }

      return Promise.resolve({
        fetch: true,
        data: null,
        timer_ended: false,
      });
    } catch (err) {
      return Promise.reject(err);
    }
  };

  linkWithXbox = async (xboxToken: string) => {
    this.setState({ linking: true });
    let otpId = localStorage.getItem("otpId");
    const {
      error,
      ok,
      migrationError,
      retryable,
    } = await this.api.linkWithXbox(xboxToken, otpId);

    if (ok) {
      const status = await asyncPoll(this.checkMigrationStatus);
      if (status.data === statusResponse.complete) {
        localStorage.removeItem("otpId");
        this.setState({
          error,
          linking: !ok,
          success: true,
          migrationError: migrationError,
        });
        this.session.removeToken();
        this.location.replace(buildMsaLoginUrl(window.location.search));
      } else if (status.data === statusResponse.inProgress) {
        this.setState({ error: false });
      } else if (status.data === null || status.data === statusResponse.error) {
        this.setState({ error: true, linking: !ok });
      }
      return;
    }

    this.setState({
      error,
      linking: false,
      migrationError: migrationError,
      retryable: retryable,
    });
  };
  validateMigration = async (xboxToken: string) => {
    this.setState({ validating: true });

    const {
      error,
      ok,
      migrationError,
      retryable,
    } = await this.api.validateMigration(xboxToken);
    this.setState({
      ok: ok,
      error: error,
      linking: false,
      migrationError: migrationError,
      validating: false,
      retryable: retryable,
    });
  };

  @action
  private setState = (state: Partial<IMSAAccountLinkStoreState>) => {
    this.state = { ...this.state, ...state };
  };
}

export const msaAccountLinkStore = new MSAAccountLinkStore(
  msaMigrationAPI(minecraftServicesClient, session),
  session,
  window.location
);
