export type Display = "block" | "inline" | "flex";

export interface IDisplayProps {
  display?: Display;
}

export function displayToClassName(props: IDisplayProps) {
  const { display } = props;
  return display && `d-${props.display}`;
}
