import * as React from "react";
import { IStyledButtonProps } from "./buttonProps";
import { parseButtonProps } from "./parseButtonProps";

export interface ILinkButtonProps
  extends IStyledButtonProps,
  React.HTMLAttributes<HTMLLinkElement> {
  href?: string;
}

export const LinkButton: React.FunctionComponent<ILinkButtonProps> = ({
  children,
  ...props
}) => {
  const { className, ...rest } = parseButtonProps("btn", {
    ...props,
  });

  return (
    <a
      data-aem-contentname="Link Button"
      className={className}
      {...rest}>
      {children}
    </a>
  );
};
