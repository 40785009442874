import * as React from "react";
import { IStyledButtonProps } from "./buttonProps";
import { parseButtonProps } from "./parseButtonProps";

export interface IButtonProps extends IStyledButtonProps, IButtonClick {
  type?: string;
  disabled?: boolean;
  title?: string;
  ref?: React.MutableRefObject<HTMLButtonElement>;
  children?: React.ReactNode;
  id?: string;
}

export interface IButtonClick {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const cssClassName = "btn";

export const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
  ({ children, ...other }, ref) => {
    const { className, ...rest } = parseButtonProps(cssClassName, other);

    return (
      <button className={className} ref={ref} {...rest}>
        {children}
      </button>
    );
  }
) as React.ForwardRefExoticComponent<IButtonProps>;
