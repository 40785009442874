import * as React from "react";
import { classNameFromClasses, classNameFromVariant } from "../css/cssHelpers";
import { IClassNameProps } from "../css/ICssClassProps";
import { SVGImageRef, ISVGImageRefProps } from "../image/SVGImageRef";

export interface IIconProps extends IClassNameProps, ISVGImageRefProps {
  size?: "2x" | "3x" | "4x" | "5x" | "15x";
}

export const Icon: React.FunctionComponent<IIconProps> = ({
  name,
  className: cssClass,
  size,
  ...rest
}) => {
  const className = classNameFromClasses([
    "svg-icon",
    classNameFromVariant("icon", size || "1x"),
    cssClass,
  ]);

  return <SVGImageRef name={name} {...className} {...rest} />;
};
