import * as React from "react";
import { RadioIcon } from "./RadioIcon";
import { RadioLabel } from "./RadioLabel";
import { classNameFromClasses } from "../css/cssHelpers";

export interface IRadioProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  labelClassName?: string;
  radioLabelClassName?: string;
  label?: React.ReactNode;
  name: string;
}

export const Radio: React.FunctionComponent<IRadioProps> = ({
  name,
  className,
  id,
  onChange,
  checked,
  labelClassName,
  radioLabelClassName,
  children,
  label,
  ...rest
}) => (
  <div {...classNameFromClasses(["radio", className])} data-testid="radio">
    <input
      id={id}
      name={name}
      type="radio"
      onChange={onChange}
      checked={checked}
      className="btn-radio"
      data-testid="radioInput"
      {...rest}
    />
    <label htmlFor={id} className={labelClassName} data-testid="radioLabel">
      {children ? (
        children
      ) : (
        <React.Fragment>
          <RadioIcon />
          <RadioLabel className={radioLabelClassName}>{label}</RadioLabel>
        </React.Fragment>
      )}
    </label>
  </div>
);
