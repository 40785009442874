import * as React from "react";
import { Popup } from "reactjs-popup";
import { sharedEnvironment } from "@mojang/environment";
import { ProductDetails } from "./ProductDetails";
import { CartStore } from "@mojang/cart-store";

interface ProductDetailsPopupProps {
  trigger: JSX.Element;
  variantId: string;
  taxMessage: string;
  addToCart?: CartStore["addItemToTheCart"];
}

const envProducts = sharedEnvironment["dungeons.products"];

export const ProductDetailsPopup = ({
  trigger,
  variantId,
  taxMessage,
  addToCart,
}: ProductDetailsPopupProps) => {
  const product = sharedEnvironment["dungeons.products"][variantId];

  return (
    <Popup
      trigger={trigger}
      modal
      overlayStyle={{
        backgroundColor: "rgba(0,0,0, 0.8)",
        overflowY: "auto",
      }}
    >
      {(close: () => void) => (
        <ProductDetails
          product={product}
          close={close}
          products={envProducts}
          taxMessage={taxMessage}
          addToCart={addToCart}
        />
      )}
    </Popup>
  );
};
