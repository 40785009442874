import * as React from "react";
import { getSessionUsername, getToken, useTokenValidation } from "@mojang/auth";
import {
  AccountStore,
  accountStoreSymbol,
  StoresProvider,
} from "@mojang/stores-provider";
import { useObserver } from "mobx-react-lite";
import { T } from "@mojang/t-component";
import { MSAAccountNavMenuDropdown } from "./MSAAccountNavMenuDropdown";
import { AccountTypeProvider } from "@mojang/msa-migration";
import { environment } from "./environment";
import { logoutStore } from "@mojang/logout";
import { AccountNavMenuApp } from "./AccountNavMenuApp";

export const AccountNavMenuValidation = () => {
  const itemUrls = {
    login: environment["url.login"],
    profile: environment["url.profile"],
    orderHistory: environment["url.orderHistory"],
    verifyEmail: environment["url.verifyEmail"],
  };
  const token = getToken();
  const { validating, valid, isMsa } = useTokenValidation();

  return useObserver(() => {
    if (validating) {
      return null;
    }

    if (!valid) {
      return (
        <a
          data-aem-contentname="Log in"
          href={itemUrls.login}
          className="nav-link inverted">
          <T>Log in</T>
        </a>
      );
    }

    if (isMsa && getSessionUsername()) {
      return (
        <MSAAccountNavMenuDropdown
          logoutStore={logoutStore}
          getSessionUsername={getSessionUsername}
          itemUrls={itemUrls}
        />
      );
    }

    return (
      valid &&
      !isMsa && (
        <StoresProvider
          stores={[[accountStoreSymbol, AccountStore.getInstance()]]}
        >
          <AccountTypeProvider token={token}>
            <AccountNavMenuApp
              logoutStore={logoutStore}
              getSessionUsername={getSessionUsername}
              itemUrls={itemUrls}
            />
          </AccountTypeProvider>
        </StoresProvider>
      )
    );
  });
};
