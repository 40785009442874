import * as React from "react";
import { IClassNameProps } from "../css/ICssClassProps";

export interface ISVGImageRefProps extends IClassNameProps, ISVGTitleProps {
  name: string;
  className?: string;
  innerClassName?: string;
  style?: React.CSSProperties;
}

export interface ISVGTitleProps {
  title?: string;
}

export const SVGTitle: React.FunctionComponent<ISVGTitleProps> = ({ title }) =>
  title ? <title>{title}</title> : null;

export const SVGImageRef: React.FunctionComponent<ISVGImageRefProps> = ({
  name,
  className,
  style,
  innerClassName,
  title,
  ...rest
}) => (
  <svg role="presentation" className={className} style={style} {...rest}>
    <SVGTitle title={title} />
    {innerClassName ? (
      <g className={innerClassName}>
        <use xlinkHref={`#${name}`} />
      </g>
    ) : (
      <use xlinkHref={`#${name}`} />
    )}
  </svg>
);
