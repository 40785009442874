import { FetchHttpClient } from "@mojang/http";
import { environment } from "../environment";
export const xboxLiveService = new FetchHttpClient(
  environment["endpoint.xboxAuthUrl"],
  {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  }
);
