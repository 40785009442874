import * as React from "react";
import { ITokenAuthStore, tokenAuthStore } from "./TokenAuthStore";
import { useObserver } from "mobx-react-lite";

interface ITokenValidationProviderProps {
  tokenAuthenticator?: ITokenAuthStore;
}

export interface ITokenValidationConsumerProps {
  validating: boolean;
  retry: () => void;
  valid: boolean;
  error: boolean;
  isMsa: boolean;
  isChildMsa: boolean;
}

export const TokenValidationContext = React.createContext<
  ITokenValidationConsumerProps
>({
  validating: null,
  retry: () => undefined,
  valid: null,
  error: null,
  isMsa: null,
  isChildMsa: null
});

export const TokenValidationProvider: React.FunctionComponent<
  ITokenValidationProviderProps
> = ({ tokenAuthenticator = tokenAuthStore, children }) => {
  const { validate, state } = tokenAuthenticator;

  React.useEffect(
    () => {
      validate();
    },
    // We can't have state.validating and validate as dependencies, it causes an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return useObserver(() => {
    return (
      <TokenValidationContext.Provider
        value={{
          ...tokenAuthenticator.state,
          retry: validate,
        }}
      >
        {children}
      </TokenValidationContext.Provider>
    );
  });
};
