import {
  Session,
  session,
  ICookieOptions,
} from "@mojang/auth";
import { action, observable } from "mobx";
import { environment } from "./environment";
import { buildMsaLogoutUrl } from "@mojang/msa-utils";

export interface ILogoutState {
  loggingOut: boolean;
}

export interface ILogoutStore {
  state: ILogoutState;
  setLoggingOut: (loggingOut: boolean) => void;
  logout: (isMsa: boolean) => Promise<string>;
}

interface ILogoutStoreEnv {
  "cookie.options"?: ICookieOptions;
  "cookie.name": string;
  "url.postLogout": string;
}

export class LogoutStore implements ILogoutStore {
  @observable
  state: ILogoutState = {
    loggingOut: false,
  };

  constructor(
    private session: Session,
    private env: ILogoutStoreEnv = environment,
    private buildMsaLogoutURL = buildMsaLogoutUrl
  ) {}

  @action
  setLoggingOut(loggingOut: boolean) {
    this.state.loggingOut = loggingOut;
  }

  async logout(isMsa: boolean) {
    this.setLoggingOut(true);
    this.session.removeToken(
      this.env["cookie.name"],
      this.env["cookie.options"]
    );
    return isMsa
      ? this.buildMsaLogoutURL(location.search)
      : this.env["url.postLogout"];
  }
}

export const logoutStore = new LogoutStore(session);
