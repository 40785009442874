import { action, observable } from "mobx";
import { minecraftServicesClient } from "../http";
import { session } from "@mojang/auth";
import { msaMigrationAPI, MSAMigrationAPI } from "../api/msaMigrationAPI";

export interface IAccountSecurityCheckStore {
  state: IAccountSecurityCheckStoreState;
  checkAccountSecurityView: () => void;
}

export interface IAccountSecurityCheckStoreState {
  enableAccountSecurityView: boolean;
  fetching: boolean;
  error: boolean;
}

export class AccountSecurityCheckStore {
  @observable
  public state: IAccountSecurityCheckStoreState = {
    enableAccountSecurityView: false,
    fetching: true,
    error: false,
  };

  constructor(private api: MSAMigrationAPI) {}

  @action
  private setState(state: IAccountSecurityCheckStoreState) {
    this.state = state;
  }

  public async checkAccountSecurityView() {
    this.setState({ ...this.state, error: false, fetching: true });
    const { data, error } = await this.api.rollout("twofactorauth");
    this.setState({
      fetching: false,
      error: error,
      enableAccountSecurityView: data ? data.rollout : false,
    });
  }
}

export const accountSecurityCheckStore = new AccountSecurityCheckStore(
  msaMigrationAPI(minecraftServicesClient, session)
);
