import * as React from "react";
import { UnstyledButton } from "../button/UnstyledButton";
import { Icon } from "../icon/Icon";

interface IAlertDismissButtonProps {
  onClick: () => void;
  label?: string;
}

export const AlertDismissButton: React.SFC<IAlertDismissButtonProps> = ({
  label,
  onClick,
  ...rest
}) => {
  return (
    <UnstyledButton
      data-aem-contentname="Alert Dismiss button"
      type="button"
      className="close"
      aria-label={label || "Close"}
      onClick={onClick}
      {...rest}
    >
      <Icon name="pixel-close" />
    </UnstyledButton>
  );
};
