import * as React from "react";

export interface IUnstyledButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

export const UnstyledButton: React.FunctionComponent<IUnstyledButtonProps> = ({
  children,
  ...rest
}) => <button {...rest}>{children}</button>;
