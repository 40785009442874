import { action, observable } from "mobx";
import { minecraftServicesClient } from "../http";
import { session } from "@mojang/auth";
import { msaMigrationAPI, MSAMigrationAPI } from "../api/msaMigrationAPI";
export interface IResetResponse {
  errorMessage: string;
}

export interface IMSAUpsellAvailabilityStore {
  state: IMSAUpsellAvailabilityStoreState;
  fetchAvailability: () => void;
}

export interface IMSAUpsellAvailabilityStoreState {
  available: boolean;
  fetching: boolean;
  error: boolean;
}

export class MSAUpsellAvailabilityStore {
  @observable
  public state: IMSAUpsellAvailabilityStoreState = {
    available: null,
    fetching: true,
    error: false,
  };

  constructor(private api: MSAMigrationAPI) {}

  @action
  private setState(state: IMSAUpsellAvailabilityStoreState) {
    this.state = state;
  }

  public async fetchAvailability() {
    this.setState({ ...this.state, error: false, fetching: true });
    const { data, error } = await this.api.rollout("msamigration");
    this.setState({
      fetching: false,
      error: error,
      available: data ? data.rollout : false,
    });
  }
}

export const msaUpsellAvailabilityStore = new MSAUpsellAvailabilityStore(
  msaMigrationAPI(minecraftServicesClient, session)
);
