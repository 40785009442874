import { FetchHttpClient } from "@mojang/http";
import { environment } from "../environment";
export const minecraftServicesClient = new FetchHttpClient(
  environment["endpoint.minecraftServices"],
  {
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    redirect: "follow",
  }
);
