import { FetchHttpClient } from "@mojang/http";
import { environment } from "../environment";
export const accountsPublicService = new FetchHttpClient(
  environment["endpoint.accountsService"],
  {
    cache: "no-cache",
    credentials: "same-origin",
    mode: "cors",
    redirect: "follow",
  }
);
