import { NOT_SET } from "./NotSet";

declare const process: {
  env?: {
    NODE_ENV?: string;
  };
};

export function validate<E extends {}>(
  env: E,
  name: string = "GeneralEnvironment"
) {
  if (navigator.userAgent.includes("jsdom")) {
    return env;
  }

  const errors: string[] = [];
  traverse(env, (key, val) => {
    if (val === NOT_SET) {
      errors.push(`${key} is ${val}`);
    }
  });

  if (errors.length > 0) {
    console.error(
      `Error in environment configuration "${name}".

Errors:
${errors.sort().join("\n")}

This error is critical and and will lead to errors in the application.
Please change the environment config.
`
    );
  }

  return env;
}

function traverse(
  o: { [key: string]: any },
  func: (key: string, val: any) => void,
  parent = ""
) {
  for (var i in o) {
    func(`/${parent}${i}`, o[i]);
    if (o[i] !== null && typeof o[i] == "object") {
      //going one step down in the object tree!!
      traverse(o[i], func, i + "/");
    }
  }
}
