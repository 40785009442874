import { TelemetryFn } from "./telemetryFn";
import { ICookieOptions } from "./ICookieOptions";
import { NOT_SET, validate, ReCaptcha, ArkoseCaptcha } from ".";
import { LogErrorFn } from "./logErrorFn";

declare global {
  interface Window {
    sharedEnvConfig: ISharedEnvironment;
  }
}

export enum EntitlementType {
  Edition = "edition",
  Bundle = "bundle",
  Dlc = "dlc",
}

export interface DungeonsProduct {
  display_name: string;
  variant_id: string;
  price: string;
  recurring: boolean;
  redeemable_by_code: boolean;
  image: string;
  mobile_image: string;
  type: EntitlementType;
  incompatible_products: string[];
  included_products: string[];
  contents: string[];
  description: string;
}
// prettier-ignore
export interface ISharedEnvironment {
  "cookie.name": string;
  "cookie.options"?: ICookieOptions;
  "cookie.entitlementState": string;
  "currency": string;
  "enable.msaMigration": boolean,
  "enable.arkoseCaptcha": boolean,
  "endpoint.arkose": string;
  "endpoint.accountsService": string;
  "endpoint.authService": string;
  "endpoint.minecraftServices": string;
  "endpoint.xboxOAuthUrl": string;
  "endpoint.xboxAuthUrl": string;
  "endpoint.msaOAuthUrl": string;
  "language": string;
  "locale": string;
  "reCaptcha"?: ReCaptcha;
  "arkose"?: ArkoseCaptcha;
  "telemetryFn": TelemetryFn;
  "logErrorFn": LogErrorFn;
  "url.buyMinecraft": string;
  "url.forgotPassword": string;
  "url.login": string;
  "url.migratePremiumAccount": string;
  "url.profile": string;
  "url.register": string;
  "url.homePage": string;
  "url.freeTrial": string;
  "url.buyMinecraftJavaEdition": string;
  "url.changeAccountSettings": string;
  "url.setUpProfile": string;
  "url.imageBaseUrl": string;
  "url.orderSummary": string;
  /**
  * A string param that is only used in development environment\
  * to specify the callback url for `msa-sisu-dispatcher`.
  * 
  * Will end up in MSA login url:
  * @example
  * "http://127.0.0.1/minecrafttest#state=<msaStateLoginParam>&accessToken=<accessToken>"
  */
  "msaStateLoginParam": string;
  "url.supportCenter": string;
  "url.supportCenterforMSAMigration": string;
  "minecraft.entitlements": Array<string>;
  "url.xboxLogoutReturnUrl": string;
  "dungeons.products": Record<string, DungeonsProduct>;
  "url.dungeons.launcherFile"?: string;
  "enable.forcedMigration": boolean,
  showTaxMessage: boolean;
  titleId: string;
  sisuAppId: string;
  "deleteNonEssentialCookie": boolean;
  "nonEssentialCookies":Array<string>;
  "enable.newProfile": boolean;
}

// prettier-ignore
export const sharedDefaultEnvironment: ISharedEnvironment = {
  "cookie.name": NOT_SET,
  "cookie.options": null,
  "cookie.entitlementState": NOT_SET,
  "currency": NOT_SET,
  "enable.msaMigration": false,
  "enable.arkoseCaptcha": false,
  "endpoint.arkose": NOT_SET,
  "endpoint.accountsService": NOT_SET,
  "endpoint.authService": NOT_SET,
  "endpoint.minecraftServices": NOT_SET,
  "endpoint.xboxOAuthUrl": NOT_SET,
  "endpoint.xboxAuthUrl": NOT_SET,
  "endpoint.msaOAuthUrl": NOT_SET,
  "language": NOT_SET,
  "locale": NOT_SET,
  "reCaptcha": null,
  "arkose": null,
  "telemetryFn": () => undefined,
  "logErrorFn": () => undefined,
  "url.buyMinecraft": NOT_SET,
  "url.forgotPassword": NOT_SET,
  "url.login": NOT_SET,
  "url.migratePremiumAccount": NOT_SET,
  "url.profile": NOT_SET,
  "url.register": NOT_SET,
  "url.homePage": NOT_SET,
  "msaStateLoginParam": null,
  "url.freeTrial": NOT_SET,
  "url.buyMinecraftJavaEdition": NOT_SET,
  "url.changeAccountSettings": NOT_SET,
  "url.supportCenter": NOT_SET,
  "url.supportCenterforMSAMigration": NOT_SET,
  "minecraft.entitlements": [NOT_SET],
  "url.setUpProfile": NOT_SET,
  "url.xboxLogoutReturnUrl": NOT_SET,
  "url.imageBaseUrl": NOT_SET,
  "dungeons.products": {},
  "url.dungeons.launcherFile": undefined,
  "url.orderSummary": NOT_SET,
  "enable.forcedMigration": false,
  showTaxMessage: false,
  titleId: NOT_SET,
  sisuAppId: NOT_SET,
  "deleteNonEssentialCookie": false,
  "nonEssentialCookies": [NOT_SET],
  "enable.newProfile": false
};

export const sharedEnvironment = validate({
  ...sharedDefaultEnvironment,
  ...window.sharedEnvConfig,
});
