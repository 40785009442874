import * as React from "react";
import { Paragraph, Row, Col, LinkButton, CardText } from "@mojang/components";
import { EntitlementImage } from "../assets/EntitlementImage";
import { T } from "@mojang/t-component";
import { environment } from "../environment";
import { buildMsaLogoutUrl } from "@mojang/msa-utils";
import { fetchOs } from "../utils/fetchOs";
import { session } from "@mojang/auth";

export const MSAEntitlementUpsell = () => {
  const { redirectUrl } = fetchOs();
  const handleLogout = () => {
    session.removeToken(
      environment["cookie.name"],
      environment["cookie.options"]
    );
    const logoutRedirectUrl = buildMsaLogoutUrl(
      "?view=Mojang#state=logout",
      true
    );
    location.assign(logoutRedirectUrl);
  };

  return (
    <div className="border border-width-1 px-4 py-3 mb-3 bg-light">
      <h2 className="font-mc-ten text-center">
        <T>Let’s start playing Minecraft: Java Edition!</T>
      </h2>

      <Row className="d-md-flex flex-md-row-reverse">
        <Col size={12} md={6} sm={12} lg={5} className="text-center mt-2">
          <EntitlementImage width={"100%"} />
        </Col>
        <Col size={12} md={6} sm={12} lg={7} className="p-3">
          <Paragraph className="text-left pb-3">
            <T>
              It looks like you haven’t purchased Minecraft: Java Edition with
              this account yet. To join in on the fun, buy Minecraft or play a
              trial version for free.
            </T>
          </Paragraph>

          <>
            <LinkButton
              data-aem-contentname="Buy Minecraft Java linkbutton"
              block
              href={environment["url.buyMinecraftJavaEdition"]}
              colorVariant="primary"
            >
              <T>Buy Minecraft: Java Edition</T>
            </LinkButton>
            <Paragraph align="center" className="text-600 my-2">
              <T>or</T>
            </Paragraph>
            <LinkButton
              data-aem-contentname="Launcher download linkbutton"
              block
              href={redirectUrl}
              colorVariant="secondary"
              className="d-none d-md-block"
            >
              <T isHTML>{`Download launcher <br /> to play trial version`}</T>
            </LinkButton>
            <CardText className="text-left d-block d-md-none">
              <T isHTML>
                {`Visit <strong>minecraft.net/download</strong> from a desktop computer to download the launcher and play the trial version.`}
              </T>
            </CardText>
          </>
        </Col>
      </Row>
      <Paragraph align="center" className="mb-0">
        <T>Already own Minecraft with a Mojang account?</T> &nbsp;
        <a
          href="#"
          data-aem-contentname="Mojang Login button"
          onClick={e => {
            e.preventDefault();
            handleLogout();
          }}
          className="mb-1"
        >
          <T>Log in here!</T>
        </a>
      </Paragraph>
    </div>
  );
};
