import { IHttpClient, IGenericResponse } from "@mojang/http";
import { environment } from "../environment";

interface RedirectUrlResponse {
  RedirectTo: string;
}

export type XboxLiveAPI = {
  getRedirectUrl: (
    profileName: string,
    callbackUrl: string
  ) => Promise<{
    error: boolean;
    data?: RedirectUrlResponse;
  }>;
};

export function xboxLiveApi(http: IHttpClient): XboxLiveAPI {
  return {
    getRedirectUrl: async function getRedirectUrl(
      profileName: string,
      callbackUrl: string
    ) {
      const xboxCallData = {
        Callback: callbackUrl,
        CobrandId: environment.cobrandId,
        ProfileName: profileName,
        State: "postUpsell",
        UseModernGamertag: true,
        TitleId: environment.titleId,
        AppId: environment.sisuAppId,
      };
      let response: IGenericResponse<any>;

      try {
        response = await http.post<RedirectUrlResponse>("", xboxCallData);
      } catch (error) {
        return { error: true };
      }

      return {
        error: !response.ok,
        data: response.ok && (await response.json()),
      };
    },
  };
}
