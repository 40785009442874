import * as React from "react";
import {
  classNameFromClasses,
  classNameFromVariant,
} from "../../css/cssHelpers";
import { IClassNameProps } from "../../css/ICssClassProps";
import { ICustomTagProps } from "../../tag/ICustomTagProps";

export type Alignment = "start" | "center" | "end" | "between" | "around";

interface IRowProps extends ICustomTagProps, IClassNameProps {
  justifyContent?: Alignment;
  alignItems?: Alignment;
}

export const Row: React.StatelessComponent<IRowProps> = props => {
  const {
    className: cssClass,
    tag,
    justifyContent,
    alignItems,
    ...rest
  } = props;

  const className = classNameFromClasses([
    "row",
    cssClass,
    classNameFromVariant("justify-content", justifyContent),
    classNameFromVariant("align-items", alignItems),
  ]);

  const Component = tag || "div";

  return <Component {...className} {...rest} />;
};
