import * as React from "react";
import { Dropdown } from ".";
import { IDropdownProps, IDropdownItem } from "./Dropdown";

interface IDropdownNavigationProps<T> extends IDropdownProps<T> {}

export function DropdownNavigation<T extends IDropdownItem>(
  props: IDropdownNavigationProps<T>
) {
  return <Dropdown {...props} onEnterSelect={() => undefined} />;
}
