import { observable, action } from "mobx";
import { minecraftServicesClient } from "../http";
import { session } from "@mojang/auth";
import {
  getXboxIdentityToken,
  decodeXboxAccessTokens,
  getXboxAccessToken,
} from "@mojang/msa-utils";
import {
  msaMigrationAPI,
  MSAMigrationAPI,
  Errors,
} from "../api/msaMigrationAPI";

export interface IMSARegisterStore {
  register: () => void;
  error?: Errors;
  isLoading: boolean;
}

export class MSARegisterStore implements IMSARegisterStore {
  @observable
  error: Errors;

  @observable
  isLoading: boolean = true;

  constructor(private api: MSAMigrationAPI) {}

  register = async () => {
    this.setIsLoading(true);

    // Get Xbox identity token from access token
    const accessToken = decodeXboxAccessTokens(
      getXboxAccessToken(window.location.hash)
    );
    const identityToken = getXboxIdentityToken(accessToken);
    const tokenDecodeError = !identityToken;

    const response = await this.api.createShadowAccount(identityToken);

    this.setError(response.error);
    if (tokenDecodeError) {
      this.setError(Errors.RegularError);
    }
    this.setIsLoading(false);
  };

  @action
  setError = (error: Errors) => {
    this.error = error;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
}

export const msaRegisterStore = new MSARegisterStore(
  msaMigrationAPI(minecraftServicesClient, session)
);
