import * as React from "react";
import { classNameFromClasses } from "../css/cssHelpers";
import { IClassNameProps } from "../css/ICssClassProps";
import { ICustomTagProps } from "./ICustomTagProps";
import { IStyleProps } from "../css/IStyleProps";

export interface IWrapperProps
  extends ICustomTagProps,
    IClassNameProps,
    IStyleProps {}

export function createSimpleWrapper<P extends IWrapperProps = IWrapperProps>(
  defaultTag: React.ReactType = "div",
  baseClassName: string = null
): React.StatelessComponent<P> {
  const Wrapper: React.FunctionComponent<P> = props => {
    const { children, tag, className, ...rest } = props;
    const Component = tag || defaultTag;
    return (
      <Component
        {...classNameFromClasses([baseClassName, className])}
        {...rest}
      >
        {children}
      </Component>
    );
  };
  return Wrapper;
}
