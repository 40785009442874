import * as React from "react";
import { ILogFactory, LogFactory } from "./LogFactory";
import { ILog } from "./Log";
import { TelemetryFn } from ".";

export interface ILogProviderProps {
  logFactory?: ILogFactory;
  name: string;
  telemetryFn: TelemetryFn;
}

export interface ILogConsumerProps {
  log: ILog;
}

const defaultContext: ILogConsumerProps = {
  log: {
    callTelemetryFn: () => undefined,
    pageView: () => undefined,
    event: () => undefined,
  },
};

export const LogContext = React.createContext<ILogConsumerProps>(
  defaultContext
);

export const LogProvider: React.FunctionComponent<ILogProviderProps> = ({
  children,
  name,
  telemetryFn,
  logFactory = new LogFactory(telemetryFn),
}) => (
  <LogContext.Provider value={{ log: logFactory.getLog(name) }}>
    {children}
  </LogContext.Provider>
);
