import * as React from "react";
import { useFetchMessages } from "./useFetchMessages";
import { ITranslation } from "./fetchMessages";

export function useTranslatedMessages(
  translationPath: string,
  language: string
) {
  const [messages, setMessages] = React.useState<ITranslation>(null);
  useFetchMessages(translationPath, setMessages, language, []);
  return messages;
}
