import * as React from "react";
import { ILogoutStore } from "@mojang/logout";
import { GetSessionUsername } from "@mojang/auth";
import { AccountNavMenuDropdown } from "./AccountNavMenuDropdown";
import { useObserver } from "mobx-react-lite";
import { T, useTranslation } from "@mojang/t-component";

interface IMSAAccountNavMenuDropdown {
  logoutStore: ILogoutStore;
  getSessionUsername: GetSessionUsername;
  itemUrls: {
    login: string;
    profile: string;
  };
}

export const MSAAccountNavMenuDropdown = ({
  logoutStore,
  getSessionUsername,
  itemUrls,
}: IMSAAccountNavMenuDropdown) => {
  const { t } = useTranslation();

  const dropdownItems = [
    { name: t("Profile"), href: itemUrls.profile },
    { name: t("Log out") },
  ].filter(i => i.href !== null);

  return useObserver(() => {
    if (getSessionUsername()) {
      return (
        <AccountNavMenuDropdown
          logoutStore={logoutStore}
          items={dropdownItems}
          sessionUsername={getSessionUsername()}
        />
      );
    } else {
      return null;
    }
  });
};
