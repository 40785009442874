import * as React from "react";
import { HTMLProps, FC } from "react";
import { IBgColorProps } from "../css/colorVariant";
import { classNameFromClasses, classNameFromVariant } from "../css/cssHelpers";
import { IClassNameProps } from "../css/ICssClassProps";

export const Card: FC<
  IBgColorProps & IClassNameProps & HTMLProps<HTMLDivElement>
> = ({ bgColor, children, className: cssClass, ...rest }) => {
  const className = classNameFromClasses([
    "card",
    classNameFromVariant("bg", bgColor),
    cssClass,
  ]);
  return (
    <div aem-data-item="Card" {...className} {...rest}>
      {children}
    </div>
  );
};
