import * as React from "react";
import useScript from "react-script-hook";
import IReCAPTCHA, { defaultReCAPTCHA } from "./IReCAPTCHA";

declare global {
  interface Window {
    grecaptcha: IReCAPTCHA;
    [index: string]: any;
  }
}

interface IReCAPTCHAProviderProps {
  sitekey: string;
  language?: string;
  loadScriptName?: string;
}

export interface IReCAPTCHAConsumerProps {
  getToken: () => Promise<string>;
  reset: () => void;
  initiated: boolean;
  loaded: boolean;
}
const defaultContext = {
  initiated: false,
  loaded: true,
  getToken: () => Promise.resolve(undefined),
  reset: () => {},
};
const ReCAPTCHAContext = React.createContext<IReCAPTCHAConsumerProps>(
  defaultContext
);

let resolveGetToken: (value?: string | PromiseLike<string>) => void = null;

const ReCAPTCHAProvider: React.FunctionComponent<IReCAPTCHAProviderProps> = ({
  children,
  sitekey,
  loadScriptName = "googleReCaptchaScriptLoaded",
}) => {
  const ref = React.useRef();
  const [grecaptcha, setGrecaptcha] = React.useState<IReCAPTCHA>(
    defaultReCAPTCHA
  );
  const [loaded, setLoaded] = React.useState(false);
  const [id, setId] = React.useState<string | undefined>();

  useScript({
    src: sitekey
      ? `https://www.recaptcha.net/recaptcha/api.js?onload=${loadScriptName}&render=explicit`
      : "",
    async: !!sitekey,
    defer: !!sitekey,
  });

  if (!sitekey) {
    console.warn("No ReCAPTCHA sitekey provided");
    return (
      <ReCAPTCHAContext.Provider
        value={{
          ...defaultContext,
          loaded: true,
          initiated: true,
        }}
      >
        {children}
      </ReCAPTCHAContext.Provider>
    );
  }

  window[loadScriptName] = () => {
    setLoaded(true);
    setGrecaptcha(window.grecaptcha);
    setId(
      window.grecaptcha.render(ref.current, {
        sitekey,
        size: "invisible",
        callback: token => {
          resolveGetToken && resolveGetToken(token);
        },
        "expired-callback": () => {
          console.log("ReCAPTCHA expired");
          grecaptcha.reset(id);
        },
      })
    );
  };

  const reset = () => {
    grecaptcha.reset(id);
  };

  const getToken = () => {
    const token = window.grecaptcha.getResponse();
    if (token !== "") {
      return Promise.resolve(token);
    }
    setTimeout(() => grecaptcha.execute(id), 10);
    return new Promise<string>(resolve => {
      resolveGetToken = resolve;
    });
  };

  return (
    <ReCAPTCHAContext.Provider
      value={{
        getToken,
        reset,
        loaded,
        initiated: true,
      }}
    >
      {children}
      <div
        ref={ref}
        data-aem-contentname="ReCAPTCHA Wrapper"
        data-testid="ReCAPTCHAWrapper"
      />
    </ReCAPTCHAContext.Provider>
  );
};

const { Consumer: ReCAPTCHAConsumer } = ReCAPTCHAContext;
export { ReCAPTCHAConsumer, ReCAPTCHAContext, ReCAPTCHAProvider };
