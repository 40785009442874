import { ICookieOptions } from "./ICookieOptions";
import { CookieAttributes } from "es-cookie/src/CookieAttributes";

export function cleanupObjFields(obj: ICookieOptions): CookieAttributes {
  const o: any = {};
  if (obj) {
    Object.keys(obj).map(k => {
      if (obj[k] !== "") {
        o[k] = obj[k];
      }
    });
  }
  return o;
}
