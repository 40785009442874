import * as Cookies from "es-cookie";
import { environment } from "../environment";
export type IsMojang = () => boolean;
export type IsMSACheckEnabledForLogin = () => boolean;

export const isMojang: IsMojang = () => {
  const query = new URLSearchParams(window.location.search).get("view");
  let status = false;
  if (query && query.toLowerCase() === "mojang") {
    status = true;
  } else {
    status = false;
  }
  return status;
};

export const isMSACheckEnabledForLogin: IsMSACheckEnabledForLogin = () => {
  const query = new URLSearchParams(window.location.search).get("view");
  let status = false;
  if (query && query.toLowerCase() === "msa") {
    status = true;
  } else {
    status = false;
  }
  return status;
};

export const setHasEntitlement = () => {
  Cookies.set(
    environment["cookie.entitlementState"],
    "true",
    environment["cookie.options"]
  );
};