import * as React from "react";
import { classNameFromClasses } from "../css/cssHelpers";
import { ICustomTagProps } from ".";
import { IClassNameProps } from "../css/ICssClassProps";
import { IStyleProps } from "../css/IStyleProps";

export interface IElementProps
  extends ICustomTagProps,
    IClassNameProps,
    IStyleProps {}

export function createSimpleElement<P extends IElementProps = IElementProps>(
  defaultTag: React.ReactType = "div",
  baseClassName: string = null
): React.StatelessComponent<P> {
  const Element = (props: P) => {
    const { tag, className, style } = props;
    const Component = tag || defaultTag;
    return (
      <Component
        {...classNameFromClasses([baseClassName, className])}
        style={style}
      />
    );
  };
  return Element;
}
