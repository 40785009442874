import { IHttpClient } from "@mojang/http";

export type AuthAPI = {
  validate: (
    accessToken: string
  ) => Promise<{
    data: boolean;
    error: boolean;
  }>;
};

export function authAPI(http: IHttpClient): AuthAPI {
  return {
    validate: async function validate(accessToken: string) {
      if (!accessToken) {
        return { data: false, error: false };
      }
      let response;
      try {
        response = await http.post("validate", {
          accessToken,
        });
      } catch (error) {
        return { data: false, error: true };
      }

      return { data: response.status === 204, error: false };
    },
  };
}
