import { action, observable, runInAction } from "mobx";

import {
  session,
  Session,
  IAuthorizationDetails,
  authService,
} from "@mojang/auth";
import { IHttpClient } from "@mojang/http";

export interface ILoginError {
  status: number;
  message: string;
}

export interface ILoginStore {
  state: ILoginStoreState;
  login: (token?: string) => void;
  logout: () => void;
  setState: (state: ILoginStoreState) => void;
}

interface ILoginStoreState {
  email: string;
  password: string;
  loggedIn: boolean;
  error: ILoginError;
}

export class LoginStore implements ILoginStore {
  @observable state: ILoginStoreState = {
    email: "",
    password: "",
    loggedIn: false,
    error: null,
  };

  constructor(
    private authPublicService: IHttpClient,
    private session: Session
  ) {}

  @action
  setState = (state: ILoginStoreState) => {
    this.state = state;
  };

  public async login(token?: string) {
    const recaptcha = token && {
      captcha: token,
      captchaSupported: "InvisibleReCAPTCHA",
    };

    const credentials = {
      password: this.state.password,
      requestUser: true,
      username: this.state.email,
    };

    const response = await this.authPublicService.post<
      IAuthorizationDetails & {
        errorMessage: string;
      }
    >("authenticate", {
      ...recaptcha,
      ...credentials,
    });

    const json = await response.json();
    const data = {
      ...json,
      user: {
        id: json && json.user ? json.user.id : "",
        username: this.state.email,
      },
    };

    if (response.ok) {
      this.session.setToken(data);

      this.setState({
        loggedIn: true,
        email: "",
        password: "",
        error: null,
      });
    } else {
      runInAction(() => {
        this.logout();
        this.setState({
          ...this.state,
          error: {
            status: response.status,
            message: data.errorMessage,
          },
        });
      });
    }
  }

  @action
  public async logout() {
    this.session.removeToken();

    runInAction(() => {
      this.state.loggedIn = false;
    });
  }
}

export const loginStore = new LoginStore(authService, session);
