import { displayToClassName } from "../layout/IDisplayProps";
import { fontStyleToClassName } from "./fontStyleToClassName";
import { ITextElementProps } from "./ITextElementProps";
import { textAlignToClassName } from "./textAlignToClassName";
import { textColorToClassName } from "./textColorToClassName";

export function textElementPropsToClassName(props: ITextElementProps) {
  return [
    textAlignToClassName(props),
    textColorToClassName(props),
    fontStyleToClassName(props),
    displayToClassName(props),
  ]
    .filter(c => !!c)
    .join(" ");
}
