import { action, observable } from "mobx";
import { IUserDetails, AccountAPI, accountAPI } from "./api";

export const accountStoreSymbol: unique symbol = Symbol("accountStoreSymbol");

export interface IAccountStore {
  state: IAccountStoreState;
}
export interface IAccountStoreState {
  error: boolean;
  userDetails?: IUserDetails;
  fetching: boolean;
}

export class AccountStore {
  @observable public state: IAccountStoreState = {
    error: false,
    userDetails: null,
    fetching: false,
  };
  private static instance: AccountStore;

  constructor(private api: AccountAPI) {
    this.fetchUserDetails();
  }

  static getInstance() {
    if (!AccountStore.instance) {
      AccountStore.instance = new AccountStore(accountAPI());
    }
    return AccountStore.instance;
  }

  private async fetchUserDetails() {
    if (this.state.fetching) {
      return;
    }
    this.setState({ fetching: true });
    const { data, error } = await this.api.getUser();

    this.setState({ fetching: false, userDetails: data, error });
  }

  @action
  private setState(state: Partial<IAccountStoreState>) {
    this.state = { ...this.state, ...state };
  }
}
