import * as React from "react";
import { classNameFromClasses } from "../css/cssHelpers";
import { IClassNameProps } from "../css/ICssClassProps";

export interface ICardTitleProps extends IClassNameProps {
  heading?: "h1" | "h2" | "h3" | "h4";
}

export const CardTitle: React.FunctionComponent<ICardTitleProps> = ({
  children,
  className: cssClass,
  heading = "h3",
  ...rest
}) => {
  const className = classNameFromClasses([
    "card-title",
    "font-mc-ten",
    cssClass,
    heading,
  ]);
  return (
    <h1 {...className} {...rest}>
      {children}
    </h1>
  );
};
