import * as React from "react";
import { FC } from "react";

export const Loader: FC<React.HTMLProps<HTMLDivElement>> = ({
  children,
  ...rest
}) => (
  <div data-testid="loader" className="preloader" {...rest}>
    <div className="bigsquare">
      <div className="square first" />
      <div className="square second" />
      <div className="square third" />
      <div className="square fourth" />
    </div>
    <p>{children}</p>
  </div>
);
