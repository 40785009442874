import * as React from "react";
import { IColorVariantProps } from "../css/colorVariant";
import { classNameFromClasses, classNameFromVariant } from "../css/cssHelpers";
import { AlertDismissButton } from "./AlertDismissButton";

export interface IAlertProps extends IColorVariantProps {
  id?: string;
  className?: string;
  closeButtonLabel?: string;
  dismissible?: boolean;
  style?: React.CSSProperties;
  onDismiss?: () => void;
}

export const Alert: React.SFC<IAlertProps> = ({
  closeButtonLabel,
  dismissible,
  onDismiss,
  className: classNameProp,
  colorVariant,
  children,
  ...rest
}) => {
  const [open, setOpen] = React.useState(true);
  const dismissibleClass = dismissible && "alert-dismissible";
  const className = classNameFromClasses([
    "alert",
    classNameProp,
    classNameFromVariant("alert", colorVariant),
    dismissibleClass,
  ]);
  const closeAlert = () => {
    setOpen(false);
  };

  React.useLayoutEffect(
    () => {
      if (onDismiss && !open) {
        onDismiss();
      }
    },
    [onDismiss, open]
  );

  const closeButton = dismissible && (
    <AlertDismissButton
      onClick={closeAlert}
      label={closeButtonLabel}
      data-testid="AlertCloseButton"
    />
  );

  return open ? (
    <div role="alert" data-testid="alert" {...className} {...rest}>
      {children}
      {closeButton}
    </div>
  ) : null;
};
