import * as React from "react";
import { ITranslation, fetchMessages } from "./fetchMessages";

export function useFetchMessages(
  translationPath: string,
  setMessages: (messages: ITranslation) => void,
  language:string,
  deps?: readonly any[],
) {
  React.useEffect(() => {
    let mounted = true;
    const runEffect = async () => {
      const messages = await fetchMessages(translationPath, language);

      if (mounted) {
        setMessages(messages);
      }
    };
    runEffect();
    return () => { mounted = false }
  }, deps)
}