import { observable, action } from "mobx";
import { setSession } from "./loginWithXbox";
import { getEntitlement, GetEntitlementFunction } from "./getEntitlement";
import { xboxAPI, XboxAPI } from "../api/xboxAPI";
import { minecraftServicesClient } from "../http";
import { setHasEntitlement } from "./utils";
import { environment } from "../environment";

interface IMSALoginStoreState {
  loggedIn: boolean;
  error: boolean;
  authenticating: boolean;
}

export interface IMSALoginStore {
  state: IMSALoginStoreState;
  login: (
    identityToken: string,
    getEntitlement?: GetEntitlementFunction
  ) => void;
}

export class MSALoginStore implements IMSALoginStore {
  @observable
  state: IMSALoginStoreState = {
    loggedIn: false,
    error: false,
    authenticating: false,
  };

  constructor(
    private api: XboxAPI,
    private setSession: (access_token: string, userId: string) => void
  ) {}
  login = async (
    identityToken: string,
    getEntitlementFn: GetEntitlementFunction = getEntitlement
  ) => {
    this.setState({ ...this.state, authenticating: true });
    const response = await this.api.loginWithXbox(identityToken);

    if (response.error) {
      this.setState({
        error: true,
        loggedIn: false,
        authenticating: false,
      });
      return;
    }

    if (!response.ok) {
      this.setState({
        error: false,
        loggedIn: false,
        authenticating: false,
      });
      return;
    }

    const { access_token } = response.data;
    const entitlement = await getEntitlementFn(access_token);
    if (response.ok) {
      this.setSession(access_token, "");
      this.setState({
        error: false,
        loggedIn: true,
        authenticating: false,
      });
      const products = environment["minecraft.entitlements"];
      const filtered = entitlement.data.items.filter(({ name }) =>
        products.includes(name)
      );
      if (
        !entitlement.error &&
        entitlement.data.items.length > 0 &&
        filtered.length > 0
      ) {
        setHasEntitlement();
      }
      return;
    }
    this.setState({
      error: false,
      loggedIn: false,
      authenticating: false,
    });
  };

  @action
  setState = (state: IMSALoginStoreState) => {
    this.state = state;
  };
}

export const msaLoginStore = new MSALoginStore(
  xboxAPI(minecraftServicesClient),
  setSession
);
