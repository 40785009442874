import { colorVariants, colors } from "./colorVariant";

export function classNameFromVariant(
  rootClassName: string,
  variant: string | colors
) {
  return variant && `${rootClassName}-${variant}`;
}

export function classNameFromColorVariant(
  rootClassName: string,
  colorVariant: colorVariants,
  variant?: string
) {
  return (
    colorVariant &&
    [rootClassName, variant, colorVariant].filter(prop => prop).join("-")
  );
}

export function classNameFromClasses(classes: string[]) {
  const className = classes.filter(c => c && !!c).join(" ");
  return className === "" ? null : { className };
}
