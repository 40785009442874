import * as React from "react";

export type Stores<T extends Object = any> = Map<symbol, T>;

export const StoresContext = React.createContext<Stores>(new Map());

interface IStoresProviderProps {
  children: JSX.Element;
  stores: Array<[symbol, object]>;
}

export function StoresProvider({ children, stores }: IStoresProviderProps) {
  return (
    <StoresContext.Provider value={new Map(stores)}>
      {children}
    </StoresContext.Provider>
  );
}
