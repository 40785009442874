import { FetchHttpClient } from "@mojang/http";

import { getToken } from "@mojang/auth";
import { sharedEnvironment } from "@mojang/environment";
export const accountsPublicService = new FetchHttpClient(
  sharedEnvironment["endpoint.accountsService"],
  {
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    mode: "cors",
    redirect: "follow",
  }
);
