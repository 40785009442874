export interface ITranslation {
  [key: string]: string | any;
}

export const fetchMessages = async (
  translationPath: string,
  language: string
) => {
  const path = translationPath.replace("<LOCALE>", language);
  try {
    const res = await fetch(path);
    return res.ok ? ((await res.json()) as ITranslation) : null;
  } catch (error) {
    return null;
  }
};
