import { observable, action } from "mobx";
import { AuthAPI, authAPI } from "./api";
import {
  getToken,
  getSessionUsername,
  hasBearer,
  setBearer,
  removeToken,
} from "./session";
import { authService } from "./http";
import { parseJwt } from "./parseJwt";

export type isValidResponse = { error: boolean; valid?: boolean };
export interface ITokenAuthState {
  valid: boolean;
  validating: boolean;
  error: boolean;
  isMsa: boolean;
  isChildMsa: boolean;
}
export interface ITokenAuthStore {
  state: ITokenAuthState;
  validate: (token?: string, userName?: string) => void;
}

const TIME_DIFF = 15 * 60;

interface ITokenPayload {
  agg: string;
  exp: number;
  iat: number;
  iss: string;
  nbf: number;
  sub: string;
}

export class TokenAuthStore implements ITokenAuthStore {
  @observable state: ITokenAuthState = {
    valid: false,
    validating: true,
    error: false,
    isMsa: false,
    isChildMsa: false
  };

  constructor(private api: AuthAPI) {}

  private async validateJwt(token: string) {
    const now = Math.round(new Date().getTime() / 1000);
    const tokenData = parseJwt(token) as ITokenPayload;
    const valid = tokenData && tokenData.exp - now > TIME_DIFF;
    return valid;
  }

  private changeState = (
    token: string,
    data: boolean,
    error: boolean,
    name: string,
    isMsa: boolean,
    isChildmsa: boolean
  ) => {
    const valid = data && !!name;

    if (valid && !hasBearer()) {
      // Can happen when old cookie is set and valid
      setBearer(token);
    }

    if (!valid) {
      removeToken();
    }

    this.setState({
      valid,
      validating: false,
      error: error,
      isMsa: isMsa,
      isChildMsa: isChildmsa
    });
  };

  private isMsaAccount(token: string) {
    const oldTokenRegExp = RegExp(/^[0-9a-f]{32}$/);
    if (oldTokenRegExp.test(token)) {
      return false;
    }
    try {
      const json = parseJwt(token);
      return json.yggt ? false : true;
    } catch (e) {
      return false;
    }
  }

  validate = async (token = getToken(), userName = getSessionUsername()) => {
    this.setState({ validating: true });
    const isMsa = this.isMsaAccount(token);
    const isChildMsa = this.isChildMsaAccount(token);
    if (isMsa) {
      const valid = await this.validateJwt(token);
      if(isChildMsa){
        this.changeState(token, valid, false, userName, true, true);
      }else{
        this.changeState(token, valid, false, userName, true, false);
      }
    } else {
      const { data, error } = await this.api.validate(token);
      this.changeState(token, data, error, userName, false, false);
    }
  };

  @action
  private setState = (state: Partial<ITokenAuthState>) => {
    this.state = { ...this.state, ...state };
  };

  private isChildMsaAccount(token: string) {
    const oldTokenRegExp = RegExp(/^[0-9a-f]{32}$/);
    if (oldTokenRegExp.test(token)) {
      return false;
    }
    try {
      const json = parseJwt(token);
      return (json.agg=="Child") ? true : false;
    } catch (e) {
      return false;
    }
  }
}

export const tokenAuthStore = new TokenAuthStore(authAPI(authService));
