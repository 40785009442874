import * as React from "react";
import { ILogoutStore } from "@mojang/logout";
import { DropdownNavigation } from "@mojang/components";
import { useTokenValidation } from "@mojang/auth";
interface IAccountNavMenuDropdown {
  logoutStore: ILogoutStore;
  items: { name: string; href?: string }[];
  sessionUsername: string;
  location?: { assign(url: string): void };
}

export const AccountNavMenuDropdown = ({
  logoutStore,
  items,
  sessionUsername,
  location = window.location,
}: IAccountNavMenuDropdown) => {
  const { isMsa } = useTokenValidation();

  const handleLogoutClick = async (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const logoutRedirectUrl = await logoutStore.logout(isMsa);
    location.assign(logoutRedirectUrl);
  };

  const itemRefs = items.map(() => React.createRef<any>());

  return (
    <>
      <DropdownNavigation
        data-aem-contentname="Profile dropdown"
        items={items}
        itemRefs={itemRefs}
        id="profiledd1"
        buttonTitle={sessionUsername}
        buttonProps={{
          size: "sm",
          className: "nav-link inverted text-smaller font-weight-normal",
        }}
        ariaLabel="Account navigation menu"
      >
        {closeDropdown => (
          <>
            {items.map((item, i) => {
              return item.href ? (
                <a
                  data-aem-contentname="Dropdown anchor item"
                  data-testid="dropdown-anchor-item"
                  key={i}
                  href={item.href}
                  className="dropdown-item text-truncate"
                  ref={itemRefs[i]}
                  tabIndex={0}
                  onClick={closeDropdown}
                >
                  {item.name}
                </a>
              ) : (
                <button
                  data-aem-contentname="Dropdown button item"
                  data-testid="dropdown-button-item"
                  key={i}
                  className="dropdown-item text-truncate text-uppercase"
                  ref={itemRefs[i]}
                  tabIndex={0}
                  onClick={e => {
                    handleLogoutClick(e);
                  }}
                  onKeyDown={e => e.key === "Enter" && handleLogoutClick(e)}
                >
                  {item.name}
                </button>
              );
            })}
          </>
        )}
      </DropdownNavigation>
    </>
  );
};
