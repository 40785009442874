import * as React from "react";
import { TokenValidationProvider } from "@mojang/auth";
import { environment } from "./environment";
import { TranslationProvider } from "@mojang/t-component";
import { useTranslatedMessages } from "@mojang/i18n";
import { AccountNavMenuValidation } from "./AccountNavMenuValidation";

export const AccountNavMenuAppContainer = () => {
  const { translationPath, language } = environment;
  const messages = useTranslatedMessages(translationPath, language);
  return (
    messages && (
      <TokenValidationProvider>
        <TranslationProvider translation={messages}>
          <AccountNavMenuValidation />
        </TranslationProvider>
      </TokenValidationProvider>
    )
  );
};
