import { environment } from "../environment";

export function getEnvironmentParam(search?: string, callbackUrl?: string) {
  const urlPattern = new RegExp(/\?.+/g);
  const env =
    urlPattern.test(search) || urlPattern.test(callbackUrl) ? "&env=" : "?env=";
  const envName =
    environment["environmentName"] !== ""
      ? env + environment["environmentName"]
      : "";

  return envName;
}
