import * as React from "react";
import useScript from "react-script-hook";

declare global {
  interface Window {
    [index: string]: any;
  }
}

interface IArkoseCAPTCHAProviderProps {
  publickey: string;
  baseArkoseEndpoint: string;
  language?: string;
  loadScriptName?: string;
}

export interface IArkoseCAPTCHAConsumerProps {
  getToken: () => Promise<string>;
  reset: () => void;
  initiated: boolean;
  loaded: boolean;
}
const defaultContext = {
  initiated: false,
  loaded: false,
  getToken: () => Promise.resolve(undefined),
  reset: () => {},
};
const ArkoseCAPTCHAContext = React.createContext<IArkoseCAPTCHAConsumerProps>(
  defaultContext
);
let resolveGetToken: (value?: string | PromiseLike<string>) => void = null;

const cleanToken = (longToken: string) => {
  let splitTokenArray = longToken.split("|");
  let matchR = /^r=/;
  let matchPk = /^pk=/;
  let r;
  let pk;
  let token;

  splitTokenArray.map(res => {
    matchR.test(res) && (r = res);
    matchPk.test(res) && (pk = res);
  });

  token = splitTokenArray[0] + "|" + r + "|" + pk;
  return token;
};

const ArkoseCAPTCHAProvider: React.FunctionComponent<
  IArkoseCAPTCHAProviderProps
> = ({
  children,
  publickey,
  baseArkoseEndpoint,
  language,
  loadScriptName = "setupEnforcement",
}) => {
  let Arkose: any;
  const [loaded, setLoaded] = React.useState(false);
  const [token, setToken] = React.useState();

  useScript({
    src: publickey ? `${baseArkoseEndpoint}v2/${publickey}/api.js` : "",
    async: !!publickey,
    defer: !!publickey,
    id: "arkosescript",
    "data-callback": loadScriptName,
  });

  if (!publickey) {
    console.warn("No ArkosCAPTCHA sitekey provided");
    return (
      <ArkoseCAPTCHAContext.Provider
        value={{
          ...defaultContext,
          loaded: true,
          initiated: true,
        }}
      >
        {children}
      </ArkoseCAPTCHAContext.Provider>
    );
  }

  window[loadScriptName] = (enforcement: any) => {
    window.Arkose = enforcement;
    enforcement.setConfig({
      language: language,
      onReady: function() {
        setLoaded(true);
      },
      onCompleted: function(t: { token: any }) {
        setToken(t.token);
        let token = cleanToken(t.token);
        resolveGetToken && resolveGetToken(token);
      },
    });
  };

  const reset = () => {
    window.Arkose.reset();
  };

  const getToken = async () => {
    loaded
      ? window.Arkose.run()
      : (window[loadScriptName](), window.Arkose.run());

    return new Promise<string>(resolve => {
      resolveGetToken = resolve;
    });
  };

  return (
    <ArkoseCAPTCHAContext.Provider
      value={{
        getToken,
        reset,
        loaded,
        initiated: true,
      }}
    >
      {children}
      <div
        data-aem-contentname="ArkoseCAPTCHA Wrapper"
        data-testid="ArkoseCAPTCHAWrapper"
      />
    </ArkoseCAPTCHAContext.Provider>
  );
};

const { Consumer: ArkoseCAPTCHAConsumer } = ArkoseCAPTCHAContext;
export { ArkoseCAPTCHAConsumer, ArkoseCAPTCHAContext, ArkoseCAPTCHAProvider };
