import { environment } from "../environment";
import { getEnvironmentParam } from "./getEnvironmentParam";
const { xboxMSAClientId } = environment;

export function buildMsaLogoutUrl(search?: string, redirectToLogin?: boolean) {
  const envName = getEnvironmentParam(search);
  const urlSearch = redirectToLogin ? "/login" + search : search;
  const ru = encodeURIComponent(
    environment["url.xboxLogoutReturnUrl"] + urlSearch + envName
  );
  const params = ["state=logout", `ru=${ru}`].join("&");
  const redirect = encodeURIComponent(
    `${environment["endpoint.xboxOAuthUrl"]}signout?${params}`
  );
  return `${
    environment["endpoint.msaOAuthUrl"]
  }?client_id=${xboxMSAClientId}&redirect_uri=${redirect}`;
}
