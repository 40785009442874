import * as React from "react";
import { classNameFromClasses } from "../../css/cssHelpers";
import { IClassNameProps } from "../../css/ICssClassProps";
import { ICustomTagProps } from "../../tag/ICustomTagProps";

const DEVICE_SIZES = ["xl", "lg", "md", "sm"];

export type ColumnSizes =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | "auto";

interface IColProps extends ICustomTagProps, IClassNameProps {
  children?: React.ReactNode;
  size?: ColumnSizes;
  sm?: ColumnSizes;
  md?: ColumnSizes;
  lg?: ColumnSizes;
  xl?: ColumnSizes;
}

export const Col = React.forwardRef<HTMLElement, IColProps>((props, ref) => {
  const { className: cssClass, size, tag, xl, lg, md, sm, ...rest } = props;
  const sizes: {
    [index: string]: any;
  } = { xl, lg, md, sm };

  const colClassNames = DEVICE_SIZES.map(s => {
    const prop = sizes[s] as ColumnSizes;
    return prop ? `col-${s}-${prop}` : null;
  }).filter(col => !!col);

  const colClassName = size
    ? `col-${size}`
    : colClassNames.length > 0
    ? null
    : "col";

  const className = classNameFromClasses([
    colClassName,
    ...colClassNames,
    cssClass,
  ]);

  const Component = tag || "div";

  return <Component {...className} {...rest} ref={ref} />;
});
